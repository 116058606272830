//font size

.font-6 {
    font-size: 6px;
}

.font-8 {
    font-size: 8px;
}

.font-10 {
    font-size: 10px!important;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-17 {
    font-size: 17px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-21 {
    font-size: 21px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}
.font-34{
    font-size: 34px;
}
.font-36{
    font-size: 36px;
}
.font-38{
    font-size: 38px;
}
.font-40{
    font-size: 40px;
}
.font-42{
    font-size: 42px;
}
.font-44{
    font-size: 44px;
}
.font-46{
    font-size: 46px;
}
.font-48{
    font-size: 48px;
}
.font-50{
    font-size: 50px;
}
.font-52{
    font-size: 52px;
}
















// font-weight
.fw-4 {
    font-weight: 400 !important;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600 !important;
}

.fw-7 {
    font-weight: 700;
}