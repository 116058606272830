/* You can add global styles to this file, and also import other style files */
@import "./style/color.scss";
@import "./style/font.scss";
@import "./style/layout.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.curser {
  cursor: pointer;
}

.f18 {
  font-size: 18px;
}

.filter-date.clender {
  display: flex;
  padding: 6px 7px !important;
}

.filter-date.clender>img {
  width: 17px;
}

.filter-date.clender input {
  width: 79%;
}

.plan-dots {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.mb-2 {
  margin-bottom: 10px;
}

.mat-select-trigger {
  position: relative;
}

.font-11 {
  font-size: 11px;
}

.invit-div.assign1 {
  display: contents;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: 14px !important;
}

.mat-select-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 256px;
  min-width: 100%;
  border-radius: 4px;
  outline: 0;
  margin-top: 47px !important;
}

::placeholder {
  text-transform: capitalize;
}

.pagination {
  position: fixed;
  width: 100%;
  display: flex;
  bottom: 0;
  text-transform: capitalize;
}

// .tble-res {
//   min-height: 62vh;
// }
.shippment-form-card {
  min-height: 80vh;
}

.panel-header.justify-between.d-flex.align-center {
  position: sticky;
  top: 0;
  z-index: 1234;
}

ul.ul-basic>li {
  border: none;
}

.lowertext {
  text-transform: lowercase !important;
}

img.searching-icon.cols {
  height: 10px;
}

html {
  scroll-behavior: smooth;
}

:root {
  --bs-table-striped-bg: #fafbff !important;
}

// .panel-header.justify-between.d-flex.align-center {
//   position: fixed;
//   z-index: 999;
//   width: -webkit-fill-available;
//   top: 0;
// }

.table>tr {
  /* background-color: var(--bs-table-bg); */
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table-striped>tr:nth-of-type(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped>tr:nth-child(n + 2):hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  cursor: pointer;
}

.help-search {
  margin-left: 19px;
}

button.theme-btn.border-white.contactBtn>a {
  color: red;
  text-decoration: none;
}

i.short {
  display: inline-block;
}

i.short>img {
  top: 1px;
  display: block;
  position: relative;
  left: 1px;
}

.hidden-lg-up {
  display: none;
}

.help-btn.active {
  background-color: #f8a23a;
}

.dropdown>.mat-select>.mat-select-trigger>.mat-select-value>span>.mat-select-min-line {
  color: #d0333a;
  font-size: 15px;
}

.pagination {
  margin-top: 50px;
}

.dropdown>.mat-select>.mat-select-trigger>.mat-select-value>span>.mat-select-min-line {
  color: #d0333a;
  font-size: 15px;
}

button.contact>a {
  color: #d0333a;
  text-decoration: none;
}

.icon-fil-dwon {
  float: right;
  display: flex;
  justify-content: end;
  margin-right: 21px;
}

.genrate.perc {
  margin-left: 20px;
}

.perc {
  margin-left: 20px;
}

.mailbox-red {
  fill: #d0333a;
}

::-webkit-scrollbar {
  display: none;
}

::-moz-scrollbar {
  display: none;
}

.fordmil {
  margin-bottom: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.Card.shippment-form-card.pickup>ul>li {
  border: 1px solid #d0333a;
}

.mat-select-placeholder {
  color: #363738 !important;
  font-weight: 500 !important;
}

.tab-unpaid.profile-tab {
  padding: 0px 0px 0px 0px;
}

.tab-account>.mat-tab-label.mat-tab-label-active {
  min-width: 50px !important;
  padding: 18px;
  background-color: #faeaeb;
  color: #303c42;
  font-weight: 700;
  opacity: 1;
  border-radius: 10px 0px 0px 0px;
}

.shippment-form-card.mb-3.tab-account {
  padding: 0px 0px 0px 0px;
}

.account-setting {
  padding-left: 20px;
}

.mat-progress-bar {
  height: 10px !important;
  border-radius: 12px !important;
}

.setting-p {
  padding-left: 20px;
}

img.send-tras {
  width: 9%;
  margin-right: 6px;
  margin-top: 0px;
}

.get-code1>.ng-otp-input-wrapper .otp-input:not(:last-child) {
  margin-right: 16px !important;
}

.chane-btn {
  margin: 70px 0px 70px 0px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.padding-main {
  padding: 20px 17px 6px;
}

.forward-last {
  margin-left: 43px;
}

.forward-last>.mat-radio-button.card-mat.mat-accent>.mat-radio-label>span.mat-radio-label-content {
  padding-left: 20px !important;
}

.forward-last>.mat-radio-button.mat-accent .mat-radio-container>.mat-radio-outer-circle {
  border-color: #f8a23a !important;
}

.forward-last>.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f8a23a;
}

.log-edit>.log-img {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding: 30px 0px;
}

.log-edit>.log-img1 {
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
  justify-content: center;
  padding: 0px 0px;
  height: 154px;
  object-fit: cover;
  border-radius: 9px;
}

.log-sub>.log-sub1 {
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
  justify-content: center;
  padding: 0px 0px;
  height: 154px;
  object-fit: fill;
  border-radius: 9px;
}

// .log-edit > img {
//   width: 90%;
//   margin: 0 auto;
//   text-align: center;
//   justify-content: center;
//   padding: 30px 0px;
// }

.theme-btn.border-white {
  border: 1px solid #d0333a;
  color: #ffffff;
  background-color: #fff0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

img.edit-icon {
  position: absolute;
  right: -12px;
  top: -15px;
  bottom: 5px;
}

.edit-icon-profile {
  border: 1px solid #dedede;
  width: 146px;
  height: 148px;
  border-radius: 10px;
  text-align: center;
  padding: 0px;
  margin: 37px auto;
  position: relative;
}

.header {
  background-color: #0000;
  position: absolute;
  width: 100%;
  top: 24px;
  z-index: 999;
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.mp4) no-repeat center center/cover;
}

video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
  object-fit: cover;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg,
      rgb(0 0 0 / 24%) 50%,
      rgb(0 0 0 / 19%) 50%);
  background-size: 3px 3px;
  z-index: 2;
}

.form-login {
  // position: absolute;
  // top: 60px;
  // bottom: 10px;
  // left: 0;
  // right: 0;
  // margin: auto;
  z-index: 999;
  border-radius: 20px;
  // max-width: 40%;
  // width: 100%;
  // height: auto;
  // background-color: #fff;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow-y: scroll;
}

.padding-both-side {
  padding: 40px;
}

.btn-psot {
  display: flex;
}

.inner-dashbaord {
  padding: 20px;
  // margin-top: 60px;
}

.card-div.visa {
  width: 33%;
}

.float-left.exiting-add {
  margin-top: 10px;
}

.addresslist>.mat-dialog-container {
  border-radius: 20px !important;
}

.add-edit-payment>.mat-dialog-container {
  border-radius: 10px !important;
}

.tag-div {
  text-align: center;
}

.graph-t {
  padding: 18px 10px 0px 0px;
}

.graph-r {
  padding: 18px 0px 0px 10px;
}

.card-div {
  position: relative;
}

// .tab-unpaid>.mat-tab-body-wrapper {
//   padding: 20px;
// }

table.sender-info.package-units>tr>td {
  padding: 5px 0px;
}

.hr>hr {
  margin-top: 4px;
}

.col-lg-3.offset-lg-2.pr-2.tab-e {
  float: right;
  display: flex;
  right: 0;
  position: relative;
  justify-content: end;
}

.basic-plan {
  background: #f7cc9b 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-right: 23px;
  padding: 16px 14px 4px 10px;
  font-family: "Poppins", sans-serif;
  height: auto;
  min-height: 383px;
}

.upload-proof>img {
  object-fit: cover;
  display: block;
  width: 100%;
  border-radius: 10px;
}

.stand-plan {
  background: #efa098 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-right: 23px;
  padding: 16px 14px 4px 10px;
  font-family: "Poppins", sans-serif;
  height: auto;
  min-height: 383px;
}

.pre-plan {
  background: #abaaef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-right: 23px;
  padding: 16px 14px 4px 10px;
  font-family: "Poppins", sans-serif;
  height: auto;
  min-height: 383px;
}

ul.ul-basic {
  padding: 0px 0px 2px 17px;
  font-size: 11px;
}

ul.ul-basic>li {
  color: #47464a;
  line-height: 25px;
  font-weight: 600;
}

.price {
  font-size: 30px;
  font-weight: 500;
}

.primr {
  color: #47464a;
  font-weight: 500;
  text-transform: capitalize;
}

/*Mailbox Shipment Dashboard*/
.Business-detail>.mat-radio-group>.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d0333a;
}

.Business-detail>.mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #d0333a;
}

.my-cards-div>.mat-radio-group>.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d0333a;
}

.my-cards-div>.mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #d0333a;
}

.mat-radio-outer-circle {
  border-color: #d0333a;
}

.mat-tab-body-wrapper {
  border-top: 3px solid rgba(0, 0, 0, 0.32) !important;
  top: -3px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

.images-upload>img {
  justify-content: center;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
}

img.uploadedImg {
  width: 100%;
  height: 170px;
  object-fit: fill;
  border-radius: 28px;
}

.color-white {
  color: #fff;
}

.mat-expansion-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px !important;
  border-radius: inherit;
  transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.color-tab {
  color: #f8a23a;
  margin-right: 15px;
}

.mat-menu-item {
  font-size: 14px !important;
  height: 40px !important;
  // height: auto !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif;
  line-height: 10px !important;
}

.mat-menu-panel {
  border-radius: 10px !important;
  min-height: 40px !important;
}

.make-change {
  background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
  font-size: 14px;
  border-radius: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 20px 40px 20px;
  border: none;
  display: inline-flex;
  margin-right: 20px;
}

.images-upload {
  background-color: #fff;
  /* padding: 24px 0px 0px; */
  /* width: 181px; */
  height: 170px;
  border-radius: 30px;
  text-align: center;
  margin: 0 19px;
  align-items: center;
  display: flex;
}

.inbox>.mat-dialog-container {
  border-radius: 20px !important;
}

.clickview>.mat-dialog-container {
  border-radius: 10px !important;
}

.approve>.mat-dialog-container {
  border-radius: 10px !important;
}

.clickview>.mat-dialog-container {
  background: rgba(12,
      12,
      12,
      0.432); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(48px) !important; // This be the blur
  border-radius: 10px !important;
}

::ng-deep mat-dialog-container {
  border-radius: 20px !important;
}

.ng-otp-input-wrapper[_ngcontent-hmh-c332] .otp-input[_ngcontent-hmh-c332]:not(:last-child) {
  margin-right: 15px;
}

span.zoom-icon {
  position: absolute;
  bottom: 16px;
  right: 46px;
  float: right;
}

.files>img {
  width: 62%;
}

.files {
  margin-left: 18px;
  margin-top: 14px;
}

.get-code {
  display: inline-flex;
  text-align: center;
  margin: 0 auto;
}

.h-pickup>p {
  margin-bottom: 5px;
}

.select-address {
  width: 100%;
  margin-left: 15px;
}

.forwar-checked {
  display: flex;
}

/* Scrollbar Styling */
.downlaod-icon {
  width: 40px;
  height: 40px;
  background-color: #fad9e9;
  border-radius: 11px;
  margin-left: 11px;
  padding: 10px 12px;
}

.inner-dashbaord ::-webkit-scrollbar {
  width: 10px !important;
}

.inner-dashbaord::-webkit-scrollbar-thumb {
  height: 2px !important;
}

.inner-dashbaord ::-webkit-scrollbar-track {
  background-color: #fff2d8;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.inner-dashbaord ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f8a23a;
}

.inner-dashbaord::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.inner-dashbaord::-webkit-scrollbar-track {
  background: transparent;
}

.inner-dashbaord::-webkit-scrollbar-thumb {
  background-color: red;
  border-right: none;
  border-left: none;
}

.inner-dashbaord::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.inner-dashbaord::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.in-Archived {
  border: navajowhite;
  padding: 10px;
  background: #fad9e9 0% 0% no-repeat padding-box;
  border-radius: 13px;
  color: #d0333a;
  width: 126px;
  height: 40px;
  font-family: "Poppins", sans-serif;
}

.open-scan {
  display: inline-block;
  background: #f684ad 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 86px;
  padding: 10px 23px;
  color: #fff;
  margin-bottom: 0px;
  // width: 47%;
  text-align: center;
  font-size: 14px;
  height: 45px;
}

.clickView {
  background: #808000 0% 0% no-repeat padding-box;
}

.open-scan>p>img {
  float: left;
  width: 20px;
  /*margin-right: 15px;*/
}

.main-inbox {
  display: flex;
}

.date-div {
  display: grid;
}

.inbox-card-mailbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
  padding: 20px 20px 0px;
  height: 350px;
  min-height: 450px;
  overflow: auto;
}

.Forward {
  display: inline-block;
  background: #3d9eff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 86px;
  padding: 10px 23px;
  color: #fff;
  width: 47%;
  text-align: center;
  font-size: 14px;
  height: 45px;
  margin-bottom: 10px;
}

.Forward>p>img {
  float: left;
  width: 14%;
  /*margin-right: 15px;*/
}

.hold {
  display: inline-block;
  background: #add841 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 86px;
  padding: 10px 23px;
  color: #fff;
  margin-bottom: 0px;
  width: 47%;
  text-align: center;
  font-size: 14px;
  height: 45px;
}

.hold>p>img {
  float: left;
  width: 15%;
  /* margin-right: 15px;*/
}

.mail {
  display: inline-block;
  background: #9266ec 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 86px;
  padding: 10px 23px;
  color: #fff;
  margin-bottom: 0px;
  width: 47%;
  text-align: center;
  font-size: 14px;
  height: 45px;
}

.mail>p>img {
  float: left;
  width: 15%;
  // margin-right: 15px;
  margin-top: 3px;
}

.inbox-img>img {
  width: 94%;
  height: 400px;
}

button.btn.bnt-trash {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid #ff7675;
  border-radius: 20px;
  background-color: #0000;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

ul.icon-indiftication>li {
  list-style: none;
  padding: 10px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

ul.icon-indiftication {
  padding: 0px;
  margin: 0;
  display: flex;
  margin-right: 0px;
  padding-right: 0px;
  float: right;
}

.identification {
  width: 40px;
  height: 40px;
  background-color: #e84393;
  margin: 5px;
}

.identification1>img {
  width: 100%;
  padding: 0px;
}

.identification1 {
  width: 40px;
  height: 40px;
  background-color: #ff7675;
  margin: 5px;
  border-radius: 12px;
}

.identification2 {
  width: 40px;
  height: 40px;
  background-color: #00b894;
  margin: 5px;
  border-radius: 12px;
}

.identification2>img {
  width: 100%;
  padding: 0px;
}

.identification3 {
  width: 40px;
  height: 40px;
  background-color: #0984e3;
  margin: 5px;
  border-radius: 12px;
}

.identification3>img {
  width: 100%;
  padding: 0px;
}

/*Mailbox Dashbaord Css*/
.create-mailbox-dash {
  height: 130px;
  background: #e84393 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0px 0px 20px;
}

.archive-mailbox-dash {
  height: 130px;
  background: #ff7675 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0px 0px 15px;
}

.trash-mailbox-dash {
  height: 130px;
  background: #00b894 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 10px 20px 0px 15px;
}

.img-ship1>img {
  float: right;
  padding: 14px;
  width: 13%;
  margin-top: 13px;
  margin-right: 7px;
}

/*Admin Dashboard css*/
.map>img {
  width: 97%;
}

span.date-notifiatin {
  padding: 0px 10px;
}

.notfication-header>p {
  font-size: 22px;
  text-align: center;
  padding: 12px 0px 0px;
}

table.sender-info.admin-table-services>tr>td:nth-child(1) {
  width: 61%;
}

.font-14.notarised>p {
  margin-top: -4px;
}

.downlaod-iconadmin {
  width: 30px;
  height: 30px;
  background-color: #fad9e9;
  border-radius: 8px;
  margin-left: 11px;
  padding: 5px 8px;
}

.edit-servces.admin-address {
  align-items: center;
  justify-content: center;
}

.post-text {
  font-size: 12px;
  color: #d0333a;
  margin-bottom: 0px;
  margin-top: 5px;
}

.post-form1 {
  justify-content: end;
  display: flex;
  // align-items: baseline;
}

table.sender-info.package-units.table.table-responsive.mailbox-admin>tr>td:nth-child(1) {
  width: 18%;
}

.col-lg-12.padding-main.mailbox {
  padding: 10px 17px;
}

table.sender-info>tr>td:nth-child(1) {
  width: 51%;
}

.cel-icon {
  position: absolute;
  left: 10px;
  top: 15px;
  height: 16px;
}

.close-div {
  float: right;
}

.mat-select-value {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Poppins", sans-serif !important;
}

.invit-div {
  float: left;
  display: inline-block;
}

.close-div>.mat-icon {
  justify-content: end;
  display: contents;
  color: #000;
}

.seeall {
  color: #d0333a;
  text-align: center;
}

.mat-menu-panel.custom-menu.mat-custom-notification.mat-menu-before.mat-menu-below.mat-elevation-z4.ng-star-inserted {
  min-width: 356px !important;
  max-width: 333px !important;
  top: 25px;
  position: relative;
  border-radius: 10px;
}

ul.not-ul>li.active {
  background: #fef6eb 0% 0% no-repeat padding-box;
}

.not-div>p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}

ul.not-ul {
  padding: 0px 0px 0px;
  width: 100%;
}

ul.not-ul>li {
  list-style: none;
  border-bottom: 1px solid #eee;
  padding: 9px 15px 10px;
}

span.icon-edit {
  padding-left: 10px;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.notarised>p {
  font-size: 14px;
  margin-bottom: 0px;
}

.padding-both {
  padding: 12px 0px 0px 15px;
}

.padding-right {
  padding: 12px 15px 0px 15px;
}

.white-form-card1.user-detail {
  /* border: 1px solid #CECECE;*/
  height: 346px;
}

.upload-proof-admin {
  border: 1px dashed;
  border-radius: 10px;
  border-color: #f09030;
  height: 112px;
  display: flex;
  justify-content: center;
}

.id-card {
  width: 100%;
  height: 65%;
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0px;
  /* border: 1px solid #CECECE;*/
}

button.btn.btn-reject {
  background: #d0333a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #d0333a33;
  border-radius: 86px;
  width: 150px;
  height: 45px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.time>p {
  font-size: 14px;
}

span.days-doller>input {
  width: 100%;
  border: none;
  padding: 0px 10px 0px 12px;
  text-align: center;
}

span.days-doller {
  width: 10px;
  /* border: 2px dotted; */
  border: 1px solid #cecece;
  border-radius: 9px;
  width: 80px;
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.logo-upload {
  width: 188px;
  /* border: 1px dotted #000; */
  border: 2px dashed #58626838;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 81px;
  margin: 20px auto;
}

button.btn.btn-Approve {
  background: #2dbe54 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 10px #2dbe5433;
  border-radius: 86px;
  width: 150px;
  height: 45px;
  color: #fff;
  border: none;
  margin: 0 20px;
  cursor: pointer;
}

.btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.select-service.user-name-detail {
  margin-bottom: 18px;
}

.select-service.user-name-detail>p {
  font-size: 14px;
}

.select-service.user-name-detail>span {
  font-size: 16px;
  font-weight: 600;
}

span.globe {
  margin-right: 13px;
}

.time {
  display: flex;
}

.post-servces {
  margin: 4px 0px 0px 6px;
  font-weight: 500;
  font-size: 14px;
}

.Post-card {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  height: 110px;
  /* border: 1px solid #CECECE;*/
}

.video {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;

  /* border: 1px solid #CECECE;*/
}

.down {
  background: rgba(208, 51, 58, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 36px;
  height: 35px;
  text-align: center;
  padding: 6px;
  margin: 12px 5px;
}

.down>img {
  width: 55%;
}

span.file-icons>img {
  width: 63%;
}

.pdf-download {
  justify-content: end;
  display: flex;
}

img.file-img {
  width: 10%;
}

.video-img {
  width: 14%;
}

.upload>.mat-dialog-container {
  border-radius: 18px !important;
  padding: 6px 20px;
}

.cdk-overlay-pane.invite {
  position: relative !important;
}

.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.invite>.mat-dialog-container {
  border-radius: 18px !important;
  padding: 10px 20px;
}

.sent {
  outline: none;
  display: flex;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 40px;
  width: 150px;
  height: 39px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

button.theme-btn.red.sent {
  margin: 38px 23px 0px;
  /* width: 200px; */
}

.shippment-form-card.logs>p {
  padding: 0px 13px;
  margin-bottom: 0px;
}

span.notification-icon {
  justify-content: center;
  display: flex;
  margin-right: 32px;
}

.map {
  padding: 10px 0px 0px 16px;
  margin-right: 10px;
}

.Shipment-logs {
  padding: 10px 21px 10px 0px;
}

.shippment-form-card.logs {
  height: 400px;
  min-height: 20vh;
}

.shippment-form-card>ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0 0px 3px 14px;

  li {
    list-style: none;
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 10px 0 10px 20px;
    font-size: 12px;
  }
}

.shippment-form-card>ul.square {
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 18px;
      width: 5px;
      height: 5px;
      background-color: #f8a23a;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }
  }
}

.edit-icon {
  background: #eeeff0 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 49px;
  height: 49px;
  margin-left: 20px;
  margin-top: 7px;
  padding: 14px;
  text-align: center;
}

.defaul {
  padding: 0px 10px;
}

.plan-div {
  float: left;
}

.dots-icon {
  justify-content: end;
  display: flex;
}

.fed-logo {
  width: 93px;
  height: 40px;
  border: 1px solid #7f7f7f;
  padding: 9px 10px 4px 11px;
  border-radius: 7px;
  text-align: center;
  justify-content: center;
  display: block;
}

.btn.in-Unpaid {
  background-color: #eeeef2;
  border: none;
  padding: 10px;
  width: 115px;
  border-radius: 13px;
  height: 40px;
  color: #7f7f7f;
  font-size: 12px;
}

span.file-icon1 {
  background-color: #cbcbcb;
  width: 50px;
  height: 37px;
  display: inline-block;
  border-radius: 24px 0px 0px 24px;
  padding: 6px;
  text-align: center;
}

.download-icon-admin {
  width: 100px;
  height: 43px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 24px;
  margin-left: 12px;
  padding: 2px;
  margin-top: 5px;
}

.shipment-admin {
  display: flex;
  padding: 31px 15px;
  text-align: left;
}

.create-admin-dash {
  height: 100px;
  background: #e44531 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
  border-radius: 20px;
  color: #fff;
}

.pervious-admin-dash {
  height: 100px;
  background: #fd79a8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
  border-radius: 20px;
  color: #fff;
}

.draft-admin-dash {
  height: 100px;
  background: #00cec9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
  border-radius: 20px;
  color: #fff;
}

.total-admin-dash {
  height: 100px;
  background: #7897f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
  border-radius: 20px;
  color: #fff;
}

.in-pending {
  // color: #febe3d;
  // background-color: #fff8eb;
  width: 114px;
  height: 37px;
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-size: 12px;
  text-transform: capitalize;

  &.active {
    color: #33d043;
    background: #eafaec;
  }

  &.inActive {
    color: #d0333a;
    background: #f6e6eb;
  }
}

img.meesage {
  width: 41%;
}

.card.admin.white-form-card>img {
  object-fit: contain;
  width: 100%;
  height: 255px;
}

.text-right-admin {
  display: grid;
}

.imge-icon>img {
  width: 70%;
}

.shipment-admin {
  display: flex;
  padding: 30px 24px;
  text-align: left;
}

/*Dashbaord Css*/

.shipment-div {
  width: 100%;
  display: grid;
  text-align: left;
  padding: 10px 22px;
}

.create-shipment-dash {
  height: 130px;
  background: #e44531 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 0px 0px 0px 1px;
  // margin: 10px 0px 0px 20px;
}

.pervious-shipment-dash {
  height: 130px;
  background: #fd79a8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 0px 0px 0px 15px;
  // margin: 10px 0px 0px 15px;
}

.draft-shipment-dash {
  height: 130px;
  background: #00cec9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #272d3b33;
  border-radius: 20px;
  color: #fff;
  margin: 0px 2px 0px 15px;
}

.font-100 {
  font-weight: 100;
}

.img-ship {
  float: right;
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.img-ship>img {
  float: right;
  padding: 10px;
  width: 13%;
}

.out-delivery {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff771c;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/outdelivery-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.out-delivery>.number>span {
  font-size: 28px;
  color: #ff771c;
  font-weight: 500;
}

.delivered {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #18c212;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/Delivered-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.delivered>.number>span {
  font-size: 28px;
  color: #18c212;
  font-weight: 500;
}

.On-time {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #00cec9;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/on-time-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.On-time>.number>span {
  font-size: 28px;
  color: #00cec9;
  font-weight: 500;
}

.early {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #471887;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/early-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.early>.number>span {
  font-size: 28px;
  color: #303c42;
  font-weight: 500;
}

.label-created {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #303c42;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/labelcreated-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.label-created>.number>span {
  font-size: 28px;
  color: #303c42;
  font-weight: 500;
}

.delayed {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f8a23a;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/lines.svg);

  background-repeat: no-repeat;
  background-position: right;
}

.exceptions {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5b59df;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
  width: 100%;
  background-image: url(./assets/images/exceptions-lines.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.exceptions>.number>span {
  font-size: 28px;
  color: #5b59df;
  font-weight: 500;
}

.delay1 {
  padding: 20px 10px 10px 0px;
}

.delay2 {
  padding: 20px 10px 10px 10px;
}

.delay3 {
  padding: 20px 10px 10px 10px;
}

.delay4 {
  padding: 20px 0px 10px 10px;
}

.delay5 {
  padding: 10px 10px 10px 0px;
}

.delay6 {
  padding: 10px 10px 10px 10px;
}

.delay7 {
  padding: 10px 10px 10px 10px;
}

.delay8 {
  padding: 10px 0px 10px 10px;
}

.card.delayed.white-form-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f8a23a;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  padding: 12px 18px 10px;
}

.img-Delayed {
  display: flex;
  width: 100%;
}

.number>span {
  font-size: 28px;
  color: #f8a23a;
  font-weight: 500;
}

.img-Delayed>span {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.img-Delayed>img {
  width: 10%;
  margin-right: 12px;
}

/*Account Settings*/
.del-card>img {
  width: 29%;
  margin: 0 auto;
  display: block;
}

.logo-div {
  border: 2px solid #dedede;
  border-radius: 15px;
  width: 90px;
  height: 90px;
  padding: 0px;
}

.logo-div>img {
  width: 84px;
  margin-top: 16px;
  padding: 9px;
}

.logo-div1>img {
  border: 2px solid #dedede;
  border-radius: 15px;
  width: 90px;
  height: 90px;
  padding: 0px;
  object-fit: cover;
}

.user-name {
  padding: 10px;
}

.account-min {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.Business-detail>p {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.manage-account {
  font-family: "Poppins", sans-serif;
}

.Business-detail>.mat-radio-group>.mat-radio-button .mat-accent>.mat-radio-button {
  margin-right: 15px;
}

.img>img {
  width: 30px;
  height: 44px;
}

.Business-detail.pt-4.Percentage.Markup.ng-star-inserted {
  border-top: 1px solid #eee;
  margin: 20px 0px 0px -1px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.Business-detail>.mat-radio-group>.mat-radio-button {
  margin-right: 16px;
}

.Business-detail>.row>.mat-radio-group>.mat-radio-button {
  margin-right: 16px;
}

/*Innoviace*/
.icons-innovices>img {
  // width: 44%;
  width: 100%;
  margin-right: 7px;
}

span.icons-innovices {
  float: left;
  cursor: pointer;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d0333a;
}

.color-text {
  color: #303c42 !important;
}

.mat-tab-label {
  min-width: 50px !important;
  padding: 25px;
  height: 57px !important;
  color: #303c42;
  font-weight: 700;
  opacity: 1 !important;
}

.mat-tab-label.mat-tab-label-active {
  min-width: 50px !important;
  padding: 23px;
  background-color: #faeaeb;
  color: #303c42;
  font-weight: 700;
  opacity: 1;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 4px !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

/*Address List Css*/
.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 6px solid !important;
  margin: 0px 4px;
}

.Percentage-icon {
  position: absolute;
  right: 20px;
  bottom: 24px;
}

.Business-detail.pt-4.Percentage.Markup {
  border-top: 1px solid #afb0c92e;
  margin: 20px 0px 0px 0px;
  border-bottom: 1px solid #afb0c92e;
  padding: 20px 0px 20px 0px;
}

.searching-icon1 {
  position: absolute;
  right: 19px;
  top: 19px;
  height: 14px;
}

.mat-expansion-panel-body {
  padding: 0 33px 1px !important;
}

.mat-expansion-panel-header.mat-expanded {
  min-height: 15px !important;
}

.mat-expansion-panel.ng-tns-c279-4.mat-expanded.mat-expansion-panel-spacing.ng-star-inserted {
  height: auto;
  min-height: 20px !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.mat-expansion-panel-body>p {
  font-family: "Poppins", sans-serif;
  line-height: 22px;
  margin-bottom: 0px;
}

.help-list.faq.col-lg-9 {
  padding-left: 10px;
}

.mat-expansion-panel-header {
  height: 30px !important;
}

.mat-expansion-panel-body {
  padding: 0 32px 0px !important;
  height: auto !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
  min-height: 15px;
}

.tag-div1 {
  display: inline-block;
}

.icons-packages {
  padding: 0px 10px 0px;
  text-align: right;
  float: right;
}

.icons-packages>img {
  width: 70%;
}

table.table.table-responsive.address-table.packages-table>tr>td:nth-child(1) {
  width: 28%;
}

table.table.table-responsive.address-table.packages-table>tr>td:nth-child(2) {
  width: 15%;
}

table.table.table-responsive.address-table.packages-table>tr>td:nth-child(3) {
  width: 15%;
}

table.table.table-responsive.address-table.packages-table>tr>td:nth-child(4) {
  width: 15%;
}

table.table.table-responsive.address-table.packages-table>tr>td:nth-child(4) {
  width: 13%;
}

.tag-span {
  height: 34px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  padding: 8px 17px;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  margin-right: 13px;
}

.tag-span.active {
  background: #38385c;
  color: #fff;
}

img.mr-2.re {
  margin-bottom: 5px;
}

.download-icon1 {
  top: 3px;
  position: relative;
}

span.file-icon {
  background-color: #cbcbcb;
  width: 53px;
  height: 34px;
  display: inline-block;
  border-radius: 24px 0px 0px 24px;
  padding: 6px;
  text-align: center;
}

span.don-icon {
  width: 40px;
  height: 40px;
  padding: 7px 10px 0px;
  position: absolute;
}

.download-icon {
  width: 113px;
  height: 40px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 24px;
  margin-left: 12px;
  padding: 2px;
  margin-top: 5px;
}

.add-green {
  background: #6bc4a8 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  width: 90px;
  height: 30px;

  color: #fff;
}

table.table.table-responsive.address-table .active {
  background-color: #fafbff;
}

table.table.table-responsive.address-table>tr>td {
  padding: 15px 10px 15px !important;
  color: #444444;
  font-size: 12px;
  border: none !important;
  text-transform: capitalize;
}

button.btn.add-old {
  border: none;
  width: 90px;
  height: 30px;
  background: #55d3fb 0% 0% no-repeat padding-box;
  border-radius: 15px;
  color: #fff;
  text-transform: capitalize;
}

button.btn.add-tag {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #afb0c9;
  border-radius: 15px;
  width: 90px;
  height: 30px;
  font-size: 12px;
  color: #afb0c9;
}

table.table.table-responsive.address-table>tr>th {
  font-size: 12px;
  // font-weight: 400;
  color: #505050;
}

table.table.table-responsive.address-table>tr>th {
  padding: 14px 0px 10px 10px;
  font-size: 13px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

table.table.table-responsive.address-table>tr {
  text-align: left;
  color: #505050;
  font-size: 14px;
  border: none !important;
}

.white-form-card1.my-cards-div {
  height: auto;
  min-height: 550px;
}

.card-mat.mat-accent>.mat-radio-label>.mat-radio-container {
  width: 9px !important;
}

.card-mat.mat-accent>.mat-radio-label>.mat-radio-container {
  padding-left: 0px !important;
}

.white-form-card1.shipment-card {
  height: 100%;
}

table.sender-info.package-units>tr>th {
  font-size: 12px;
  font-weight: 400;
  color: #818181;
}

tr.active.package-info>td {
  color: #303c42;
  font-weight: 600 !important;
}

.shippment-delivery>p {
  margin-bottom: 5px;
  color: #303c42;
  font-weight: 500 !important;
}

p.date-shipment>img {
  width: 3%;
  margin-right: 7px;
  top: 4px;
}

.date-shipment {
  color: #818181 !important;
  font-size: 12px;
  font-weight: 400 !important;
}

.shippment-delivery {
  margin-top: 8px;
  border-radius: 18px;
  border: 1px solid #cecece;
  background-color: #fbfcff;
  padding: 10px 15px 5px;
}

.shippment-delivery.admin {
  margin-top: 8px;
  border-radius: 18px;
  border: 1px solid #cecece;
  background-color: #fbfcff;
  padding: 15px 15px 5px 20px;
  // width: 397px;
}

.shippment-delivery.admin>p {
  margin-bottom: 5px;
  color: #303c42;
  font-weight: 500 !important;
  font-size: 13px;
}

span.in-transit.detail.admin {
  display: inline-block;
  width: 120px;
  height: 38px;
  border-radius: 38px;
  margin-left: 45px;
  text-align: center;
  font-weight: 500;
}

.edit-servces.ship-detail>p {
  top: 8px;
  position: relative;
}

p.scheduld {
  color: #818181 !important;
  font-size: 12px;
  font-weight: 400 !important;
}

span.in-transit.detail {
  display: inline-block;
  width: 150px;
  height: 45px;
  border-radius: 38px;
  margin-left: 45px;
  text-align: center;
  font-weight: 500;
}

.shipment-datte>p {
  margin-bottom: 0px;
  margin-top: 21px;
  font-size: 12px;
  color: #818181;
}

.shipment-datte>span {
  font-size: 11px;
  font-weight: 700;
  color: #303c42;
}

.select-service>p {
  margin-bottom: 0px;
  margin-top: 21px;
  font-size: 12px;
  color: #818181;
}

.select-service>span {
  font-size: 11px;
  font-weight: 700;
}

.shipment-datte {
  float: left;
  width: 45%;
}

ul.schedule-a {
  padding: 0px;
}

table.sender-info>tr>th {
  text-align: left;
}

.pointer {
  cursor: pointer;
}

// .schedule-a > li .cursor  {
//   color: #fff;
//   background-color: #D0333A;
// }

.schedule-a>li.cursor1 {
  cursor: pointer;
}

.schedule-a>li:hover {
  background-color: #d0333a;
  color: #fff;
}

.schedule-a>li:hover {
  background-color: #d0333a;
  color: #fff;
}

.schedule-a>li {
  border: 1px solid #d0333a;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  height: 42px;
  display: flex;
  padding: 8px;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #7f7f7f;
  list-style: none;
  margin-bottom: 15px;
  font-size: 14px;
}

.Card.shippment-form-card.pickup {
  padding: 20px;
}

.Filter-status>.mat-select>.mat-select-trigger>.mat-select-value {
  margin-left: 26px !important;
  font-size: 13px !important;
}

.in-transit {
  border: navajowhite;
  padding: 10px;
  background: #f6e6eb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #d0333a;
  width: 115px;
  height: 37px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-transform: capitalize;
}

.in-deliverd {
  border: navajowhite;
  padding: 10px;
  background: #eafaec 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #33d043;
  width: 115px;
  height: 37px;
  font-size: 12px;
}

.pendinglist {
  color: #febe3d !important;
  background-color: #fff8eb !important;
  width: 114px;
  height: 37px;
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-size: 12px;
  text-transform: capitalize;
}

table {
  border-collapse: collapse;
  width: 100%;
  // margin-bottom: 70px;
}

table.table.table-responsive.shippment-table>tr {
  text-align: left;
  color: #505050;
  font-size: 14px;
  border: none !important;
}

table.table.table-responsive.shippment-table>tr>td {
  padding: 9px 10px 10px !important;
  color: #444444;
  font-size: 12px;
  border: none !important;
  text-transform: capitalize;
}

// table.table.table-responsive.shippment-table .active {
//   background-color: #fafbff;
// }

table.table.table-responsive.shippment-table>tr>th {
  padding: 0px 0px 10px 10px;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}

button.theme-btn.white-btn.back-red.bulk-shipment {
  font-size: 13px;
  margin-top: 5px;
  width: 150px;
  height: 40px;
}

.theme-btn.orange.create-shipment {
  font-size: 13px;
  width: 151px;
  height: 40px;
}

.filter-icon {
  position: absolute;
  left: 10px;
  top: 18px;
  height: 10px;
}

.filter-date {
  border: 1px solid #afb0c9;
  border-radius: 10px;
  padding: 10px;
  height: 52px;
  margin-top: 2px;
  background-color: #fff;

  & input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 66%;
    margin-left: 30px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 13px;
  }
}

.selsect-date {
  border: 1px solid #afb0c9;
  border-radius: 10px;
  padding: 10px;

  & input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 92%;
  }
}

.selsect-date>img {
  top: 3px;
  position: relative;
  width: 5%;
}

span.chcek-b1 {
  float: left;
  top: 10px;
  position: relative;
  right: 5px;
}

span.chcek-b1>img {
  width: 79%;
}

.mat-menu-panel.ng-trigger.ng-trigger-transformMenu.ng-tns-c178-1.custom-menu.mat-custom-profile.mat-menu-before.mat-menu-below.mat-elevation-z4.ng-star-inserted {
  width: 200px;
  top: 35px;
  position: relative;
  border-radius: 10px;
}

.scondry-color {
  color: #303c42;
  font-weight: 600;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.doller-input input {
  width: 90% !important;
  margin-left: 44px;
}

span.img-profile {
  border: 1px solid #d0333a;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 1px;
  margin-right: 7px;
}

span.doller-icon {
  float: left;
  top: 2px;
  position: relative;
  left: 17px;

  margin-left: 0px;
}

.doller-img {
  width: 10%;
  // border-right: 1px solid #afb0c9 !important;
  position: absolute;
  top: 45px;
}

.logoutUser {
  font-size: 14px;
}

.passwordChange {
  font-size: 14px;
}

textarea.form-control.textarea {
  height: 80px;
}

.carrier-services {
  width: 100%;
}

.white-form-card.log-white-card.carrir {
  height: 300px;
}

.curior img {
  /* margin-right: 8px; */
  padding: 4px;
  height: 50px;
}

.curior.active {
  border: 1px solid #d23b42;
}

.white-form-card.log-white-card {
  margin-bottom: 114px;
}

.curior {
  border: 1px solid #7f7f7f;
  margin: 7px;
  border-radius: 10px;
  height: 84px;
  padding: 15px 22px;
}

.logo-img {
  height: 180px;
  min-height: 180px;
}

::ng-deep .mat-checkbox-frame {
  border-color: #cbcbcb;
}

.mat-checkbox {
  width: 5% !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body::-moz-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.maincolor {
  color: #e44531 !important;
  font-weight: 400;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fff;
}

.theme-btn {
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 40px;
  width: 140px;
  height: 39px;
  cursor: pointer;

  &.orange {
    background: #f8a23a;
    color: #fff;
    // box-shadow: 0px 8px 10px #d0333a33;
    font-size: 14px;
  }
}

button.theme-btn.red.signupbtn {
  margin: auto;
}

.theme-btn {
  outline: none;
  display: flex;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 40px;
  width: 150px;
  height: 39px;

  cursor: pointer;
  align-items: center;
  justify-content: center;

  &.red {
    background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    box-shadow: 0px 8px 10px #d0333a33;
    font-size: 14px;
  }

  &.border-red {
    border: 1px solid #d0333a;
    color: #d0333a;
    background-color: #fff0;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  &.white-btn {
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  &.upload-btn {
    background: rgba(248, 162, 58, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(159, 24, 20, 0.12);
    color: white;
    margin: 0 auto;
  }
}

.white-form-card {
  width: 100%;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 20px;
}

// .form.form-width {
//   width: 80%;
// }

/*New Css Add*/

span.download-icon {
  top: 3px;
  position: relative;
}

textarea::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif;

  font-size: 12px;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

textarea:-ms-input-placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

span.addpackage {
  margin-right: 10px;
}

button.btn.btn-addnewcard {
  background-color: #38385c;
  border-radius: 20px 0px 0px 20px;
  border: none;
  /* left: 0px; */
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 11px;
  padding: 8px;
  height: 34px;
  width: 122px;
  text-align: center;
}

.forgot-pass::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif;
}

.forgot-pass:-moz-placeholder {
  font-family: "Poppins", sans-serif;
}

.font-family {
  font-family: "Poppins", sans-serif;
  line-height: 26px;
  font-size: 13px !important;
}

.img-logo {
  width: 100%;
}

.footer-bar {
  height: 30px;
  background-color: #1a1a35;
  position: relative;
}

img.fget-img {
  width: 50%;
}

.padding-forget {
  padding: 8px;
}

.text-center.cursor.dont {
  margin-top: 40px;
  margin-bottom: 30px;
}

.theme-btn.border-red {
  width: 120px !important;
  height: 40px !important;
}

label {
  color: #000000;
}

.sideImg img {
  width: 100%;
}

.border-bottom-img {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.account {
  margin-top: -80px;
}

.topText {
  line-height: 45px;
}

.contactBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.main-logo {
  position: absolute;
  top: 25px;
  right: 86px;
  width: 200px;
}

.error {
  color: red;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}

.text-center.cursor.dont {
  margin-top: 12px;
  margin-bottom: 12px;
}

.logo {
  width: 100%;
}

:host::ng-deep.mat-checkbox-inner-container {
  margin-top: 6px !important;
}

.agree {
  line-height: 24px;
  font-family: "Poppins";
  /* font-weight: 300; */
  color: #000 !important;
}

.mat-checkbox-layout>label {
  color: #000 !important;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
  color: #fff !important;
}

/*Sender Address*/

.virtual-section>.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  border-radius: 20% !important;
  background-color: #f8a23a !important;
}

.virtual-section>.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f8a23a !important;
  border-radius: 50% !important;
}

.virtual-section>.mat-accent .mat-checkbox-frame {
  border-radius: 50% !important;
}

.virtual-section>.mat-checkbox {
  width: 11% !important;
  margin-top: 8px;
}

.signup>.mat-checkbox {
  width: 11% !important;
}

// .signup > .mat-checkbox-background, .mat-checkbox-frame {
//   border-radius:20%!important;
//   border:1px solid #575858!important ;
// }
.mat-checkbox {
  font-family: "Poppins", sans-serif !important;
}

.signup>.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  border-radius: 20% !important;
  background-color: #d0333a !important;
}

.signup>.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #d0333a;
  border-radius: 20% !important;
}

.mat-checkbox {
  font-family: "Poppins", sans-serif !important;
}

.Residential>.mat-checkbox-layout>.mat-checkbox-inner-container {
  height: 18px !important;
  width: 18px !important;
  margin: 4px 8px !important;
}

.Residential>.mat-checkbox-layout .mat-checkbox-label {
  line-height: 20px !important;
  bottom: 4px !important;
  position: relative;
}

.Residential>.mat-checkbox-checked.mat-accent label {
  color: #000;
  font-weight: 600;
}

.Residential>.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e44531 !important;
}

.verify-icon {
  top: 2px;
  position: relative;
  left: 2px;
}

/*addional info css*/
.font-weight {
  color: #000000;
  font-weight: 500;
}

.green-color {
  color: #33d043 !important;
}

table.sender-info.package-units>tr>th:nth-child(2n) {
  width: 14%;
}

table.sender-info>tr>td>p {
  margin-bottom: 0px;
}

.white-form-card1 {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 15px;
  // word-break: break-all;
  overflow-x: scroll;
}

table.sender-info>tr {
  font-size: 12px;
  margin: 22px;
}

table.sender-info>tr>td {
  color: #4c4c4c;
  padding: 5px 0px;
  text-transform: capitalize;
}

table.table.table-responsive.shippment-table.table-bordered.shipdetail {
  margin-bottom: 10px !important;
}

.edit {
  background-color: #f4f4f4;
  float: right;
  width: 30px;
  position: relative;
  height: 30px;
  z-index: 9999;
  border-radius: 7px;
  text-align: center;
  justify-content: center;
  padding: 4px;
}

.edit.icns {
  z-index: 0 !important;
}

/*add shippment css*/
.mat-icon {
  position: relative;
  left: -34px;
  color: #d1373e;
  /* top: -35px; */
  bottom: 178px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-70 {
  padding-top: 70px;
}

.edit-servces>p {
  margin-bottom: 0px;
}

.verify {
  font-size: 10px;
  color: #d23b41;
  margin-top: -2px !important;
}

.add-card.float-right>p {
  background-color: #38385c;
  border-radius: 20px 0px 0px 20px;
  /* left: 0px; */
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 11px;
  padding: 8px;
  height: 34px;
  width: 122px;
  text-align: center;
}

/*Account Type css*/
.edit-servces>p {
  color: #303c42;
  font-weight: 600;
}

a.refersh>img {
  width: 74%;
}

.refersh {
  float: right;
  right: 0px;
  /* top: -13px; */
  position: relative;
  left: 30px;
  bottom: 0px;
}

button.theme-btn.back {
  /* background-color: #ffff; */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 86px;
  margin-right: 20px;
  color: #d1373e;
  font-weight: 600;
}

.Virtual {
  height: 60px;
  margin-top: 12px;
}

.sideImg img {
  width: 100%;
}

// .virual-img{
//   background-image: url(../../../../assets/images/account-type.png);
// }
.shipping-img {
  background-image: url(./assets/images/side-img-ship.svg);
  background-size: 100%;
}

.border-bottom-img {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.account {
  margin-top: 60px;
}

.topText {
  line-height: 40px;
}

.contactBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.logo {
  position: absolute;
  top: 20px;
  right: 80px;
}

.list-item {
  padding: 10px;
  font-size: 13px;
  border-top: 1px solid rgba(167, 167, 167, 0.5);
}

.card-list {
  margin-top: 24px;
}

.shipping-icon {
  position: absolute;
  top: -20px;
  width: 60px;
  right: 10px;
}

.mailBox-icon {
  position: absolute;
  top: -20px;
  width: 60px;
  right: 10px;
}

.theme-btn.border-red {
  width: 120px !important;
  height: 40px !important;
}

.card-list>ul>li {
  font-size: 13px;
  padding: 7px 0px 7px;
  border-top: none;
  position: relative;
  color: #000000b8;
}

.card-list>ul> :after {
  content: "";
  position: absolute;
  z-index: 1;
  border-top: 1px solid #d1d0d0;
  display: block;
  top: 0%;
  right: 0%;
  height: 100%;
  left: -9%;
}

.card-list>ul {
  padding: 0px 20px 0px;
}

/*new account  info*/

.bg-info {
  background-image: url(./assets/images/bg-img.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0px 0px;
}

.theme-btn.border-red {
  width: 120px !important;
  height: 40px !important;
}

.border-bottom-img {
  width: 100%;
  /* position: absolute; */
  bottom: 0;
}

/*application proof css*/
.white-form-card {
  width: 100%;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 15px;
  height: 425x;
}

.card.saving.white-form-card>img {
  object-fit: fill;
  width: 100%;
  height: 405px;
}

mat-icon.mat-icon.notranslate.first_icon.material-icons.mat-icon-no-color {
  position: absolute;
  right: -1px;
  top: 34px;
  color: red;
}

.border-bottom-img {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.upload-proof {
  border: 1px dashed;
  border-radius: 10px;
  border-color: #f09030;
  height: 150px;
  display: flex;
  justify-content: center;
}

// .upload-proof img {
// width:100%;
// }
ul.uloading-instruction {
  margin: 0px;
  line-height: 30px;
  margin-left: 6px;
}

img.ml-2.doit {
  width: 2%;
  margin-right: 2px;
  top: 1px;
  position: relative;
}

/*UPload proof*/

.upload-file {
  background-color: rgba(250, 250, 250, 1);
  padding: 28px 20px;
  border: 1px dashed rgba(155, 159, 183, 1);
  border-radius: 10px;
}

.upload-btn {
  width: 100px !important;
  height: 28px;
  font-size: 10px;
}

.upload-bar {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 19px rgba(25, 74, 132, 0.2);
  border: 0.5px solid rgba(208, 51, 58, 0.5);
  border-radius: 10px;
}

.post-office {
  height: 100vh;
}

.dowanloadForm {
  color: black;
}

/*Client Info*/

.cler>p {
  text-align: end;
  text-decoration: underline;
  opacity: 0.3;
  font-weight: 300;
}

.file {
  background-color: #e44531;
  padding: 10px;
  border-radius: 7px;
  height: 38px;
  width: 38px;
}

.close {
  height: 38px;
  width: 38px;
  text-align: center;
  bottom: 0;
  display: flex;
}

.close>img {
  width: 50%;
  margin-left: 18px;
}

p.float-right.pack-info {
  float: left;
  position: relative;
  width: 100%;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f8a23a !important;
}

p.float-right.pack-info {
  float: left;
  position: relative;
  width: 100%;
}

.count {
  color: #474849;
  font-size: 50px;
  font-weight: 500;
  margin: -17px 22px 0px;
  justify-content: center;
  display: flex;
}

img.search-icon {
  position: absolute;
  right: 30px;
  top: 49px;
  height: 14px;
}

/*arrival info*/

p.float-right.pack-info {
  float: left;
  position: relative;
  width: 100%;
}

.day1 {
  background-color: #e8e8ff;
  border-radius: 11px 11px 0px 0px;
}

.day2 {
  background-color: #ecffdb;
  border-radius: 11px 11px 0px 0px;
}

.day3 {
  background-color: #ffe8fa;
  border-radius: 11px 11px 0px 0px;
}

.day4 {
  background-color: #ffeedb;
  border-radius: 11px 11px 0px 0px;
}

tr.maincolor>td {
  color: #d0333a !important;
}

.color-black {
  color: #000 !important;
}

.card {
  border: 1px solid #d7d7e4;
  border-radius: 11px;
}

.back-red {
  color: #d1373e !important;
}

img.card-img {
  width: 100%;
}

.card-detail {
  border-radius: 11px 11px 0px 0px;
  height: 80px;
  padding: 12px 22px;
}

.card-detail>p {
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.day-service {
  font-size: 18px;
  font-weight: 600;
}

.estimated-arrival {
  font-size: 13px;
  bottom: 5px;
  position: relative;
}

.shipping {
  padding: 10px 10px;
}

.card-inner1 {
  border: 1px solid #afb0c9;
  margin: 15px;
  border-radius: 18px;
  padding: 17px 19px;
  height: 90px;
  position: relative;
}

.card-inner {
  margin: 15px;
  border-radius: 18px;
  // padding: 25px 19px;
  height: 80px;
  position: relative;
}

.dayService {
  display: inline-grid;
}

.dayprice p {
  font-size: 30px;
  font-weight: 500;
}

span.package.price-card {
  top: 2px;
  position: relative;
}

.font-time {
  font-size: 13px;
  color: #000000;
  opacity: 0.7;
}

.reb-inner {
  background-color: #faeaeb;
  padding: 17px 19px;
  border-radius: 18px;
  position: relative;
}

.reb-inner1 {
  background-color: #fff;
  padding: 17px 19px;
  border-radius: 20px;
  position: relative;
  border: 1px solid #afb0c9;
}

/* common */
.ribbon {
  width: 173px;
  height: 168px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #d0333a;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 70px;
  padding: 2px 0;
  background-color: #d0333a;
  color: #fff;
  font: 700 8px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: 119px;
  top: 13px;
  transform: rotate(45deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

/*Shippment Css */

.shippment-form-card {
  background-color: #eee;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  padding: 10px 0px 25px 0px;
}

img.searching-icon {
  position: absolute;
  right: 30px;
  top: 18px;
  height: 15px;
  width: 15px;
}

@media only screen and (max-width: 2450px) and (min-width: 1921px) {
  .form {
    width: 80%;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 400;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden !important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  .form {
    // width: 500px;
    // margin-top: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 50px 0px 50px;
  }

  .watch-video {
    padding-right: 15px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 514px !important;
    padding-right: 17px;
  }

  video {
    width: 100%;
    height: 100%;
    height: 467px;
    object-fit: cover;
    // top: 7px;
    position: relative;
  }

  /*Client Dashboard css*/

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 28px;
    left: 0;
    right: 0;
    float: right;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    img.ng-star-inserted {
      width: 80%;
    }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 8px 14px;
          margin: 4px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #fff;
          width: 95%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #eeefef;
            border-left: 4px solid #2826c1 !important;
            border-top: 0;
            border-bottom: 0;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  // li:nth-last-child(3n - 2) {
  //   border-top: 1px solid #5d676d;
  // }

  .card-list>ul>li {
    font-size: 13px;
    padding: 7px 0px 7px;
    border-top: none;
    position: relative;
    color: #000000b8;
  }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 14px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: rgb(223 18 57 / 90%);
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 18px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1666px) {
  // .pagination {
  //   width: 87% !important;
  // }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .col-lg-11.contetnt-inbox {
    max-width: 100%;
  }

  .mobile-icon-shipment {
    display: none;
  }

  #dashboad-ul {
    overflow: hidden;
  }

  #dashboad-ul1 {
    overflow: auto;
    height: 100%;
  }

  .mat-icon {
    position: absolute;
    left: 318px;
    color: #d1373e;
    top: -6px;
    bottom: 0px;
    display: flex;
    float: right;
  }

  .add-card.float-right>p {
    background-color: #38385c;
    border-radius: 20px 0px 0px 20px;
    /* left: 0px; */
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 11px;
    padding: 11px;
    height: 34px;
    width: 122px;
    text-align: center;
  }

  .inbox-padding {
    padding: 0px 40px 0px 79px;
  }

  .Forward>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .hold>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .mail>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
    margin-top: 3px;
  }

  .img-ship>img {
    float: right;
    padding: 10px;
    width: 9%;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 78%;
    margin-left: 26px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 16px;
    right: 80px;
    float: right;
  }

  .img-ship1>img {
    float: right;
    padding: 10px;
    width: 8%;
    margin-top: 13px;
    margin-right: 7px;
  }

  .card-div.visa {
    width: 27%;
  }

  .form {
    width: 80%;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 18px 30px 3px;
    height: 100%;
    /* overflow: scroll; */
    margin-bottom: 15px;
  }

  .shipping-img {
    background-image: url(./assets/images/side-img-ship.svg);
    background-size: 100%;
  }

  .sideImg {
    top: 22px;
    position: relative;
    bottom: 0px;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .text-center.cursor.dont {
    margin-top: 21px;
    margin-bottom: 16px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden !important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  .form {
    // width: 500px;
    // margin-top: 90px;
    display: flex;
    flex-direction: column;
    margin: 40px 50px 0px 50px;
  }

  .watch-video {
    padding-right: 15px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 517px !important;
    padding-right: 17px;
  }

  video {
    width: 100%;
    height: 100%;
    height: 467px;
    object-fit: cover;
    // top: 7px;
    position: relative;
  }

  /*Client Dhasboard css*/
  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .shipment-red {
    float: left;
    width: 11% !important;
    top: 3px;
    position: relative;
  }

  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    // img.ng-star-inserted {
    //   width: 80%;
    // }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 12px 0px 0px 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 15px 14px;
          margin: 0px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #c3c3c3;
          width: 95%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #586268;
            border-left: 4px solid #f8a23a !important;
            border-top: 0;
            border-bottom: 0;
            padding: 15px 10px;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  // li:nth-last-child(3n - 2) {
  //   border-top: 1px solid #5d676d;
  // }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 20px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 18px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 28px;
    left: 0;
    right: 0;
    float: right;
  }

  ul.ul-dash {
    margin-top: 0px;
    margin: 0px;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    float: right;
    top: 199px;
  }

  .dropdown {
    height: 46px;
    background-color: #fff;
    margin-top: 16px;
    width: 230px;
    margin: 16px 10px 16px;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
  }

  span.shipment-red>img {
    width: 90%;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    // margin-bottom: 13px;
    width: 100% !important;
  }

  .dropdown>.shipment-dropdown>.mat-select-value {
    margin-left: 0px !important;
  }

  .schedule-a>li.cursor1 {
    cursor: pointer;
    border: 1px solid #d0333a;
  }

  ul.uloading-instruction>li {
    border: none;
  }
}

@media only screen and (max-width: 1660px) and (min-width: 1442px) {
  // .pagination {
  //   width: 85% !important;
  // }

  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .mobile-icon-shipment {
    display: none;
  }

  #dashboad-ul {
    overflow: hidden;
  }

  #dashboad-ul1 {
    overflow: auto;
    height: 100%;
  }

  .mat-icon {
    position: absolute;
    left: 318px;
    color: #d1373e;
    top: -6px;
    bottom: 0px;
    display: flex;
    float: right;
  }

  .add-card.float-right>p {
    background-color: #38385c;
    border-radius: 20px 0px 0px 20px;
    /* left: 0px; */
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 11px;
    padding: 11px;
    height: 34px;
    width: 122px;
    text-align: center;
  }

  .inbox-padding {
    padding: 0px 40px 0px 79px;
  }

  .Forward>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .hold>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .mail>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
    margin-top: 3px;
  }

  .img-ship>img {
    float: right;
    padding: 10px;
    width: 9%;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 78%;
    margin-left: 21px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 16px;
    right: 80px;
    float: right;
  }

  .img-ship1>img {
    float: right;
    padding: 10px;
    width: 8%;
    margin-top: 13px;
    margin-right: 7px;
  }

  .card-div.visa {
    width: 27%;
  }

  .form {
    width: 80%;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 18px 30px 3px;
    height: 100%;
    /* overflow: scroll; */
    margin-bottom: 15px;
  }

  .shipping-img {
    background-image: url(./assets/images/side-img-ship.svg);
    background-size: 100%;
  }

  .sideImg {
    top: 22px;
    position: relative;
    bottom: 0px;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .text-center.cursor.dont {
    margin-top: 21px;
    margin-bottom: 16px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden !important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  .form {
    // width: 500px;
    // margin-top: 90px;
    display: flex;
    flex-direction: column;
    margin: 40px 50px 0px 50px;
  }

  .watch-video {
    padding-right: 15px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 517px !important;
    padding-right: 17px;
  }

  video {
    width: 100%;
    height: 100%;
    height: 467px;
    object-fit: cover;
    // top: 7px;
    position: relative;
  }

  /*Client Dhasboard css*/
  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .shipment-red {
    float: left;
    width: 11% !important;
    top: 3px;
    position: relative;
  }

  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }

  .cursor {
    cursor: pointer;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    // img.ng-star-inserted {
    //   width: 80%;
    // }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 12px 0px 0px 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 15px 14px;
          margin: 0px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #c3c3c3;
          width: 100%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #586268;
            border-left: 4px solid #f8a23a !important;
            border-top: 0;
            border-bottom: 0;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  // li:nth-last-child(3n - 2) {
  //   border-top: 1px solid #5d676d;
  // }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 20px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 18px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 28px;
    left: 0;
    right: 0;
    float: right;
  }

  ul.ul-dash {
    margin-top: 0px;
    margin: 0px;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    float: right;
    top: 199px;
  }

  .dropdown {
    height: 46px;
    background-color: #fff;
    margin-top: 16px;
    width: 230px;
    margin: 16px 10px 16px;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
  }

  span.shipment-red>img {
    width: 90%;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    // margin-bottom: 15px;
    width: 100% !important;
  }

  .dropdown>.shipment-dropdown>.mat-select-value {
    margin-left: 0px !important;
  }

  .schedule-a>li.cursor1 {
    cursor: pointer;
    border: 1px solid #d0333a;
  }

  ul.uloading-instruction>li {
    border: none;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1439px) {
  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .mobile-icon-shipment {
    display: none;
  }

  img.invoice-download {
    width: 38%;
  }

  #dashboad-ul {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  #dashboad-ul1 {
    width: 100%;
    height: 99%;
    overflow: auto;
  }

  .mat-icon {
    position: absolute;
    left: 235px;
    color: #d1373e;
    top: -6px;
    bottom: 0px;
    display: flex;
    float: right;
  }

  .shipping-drop {
    font-size: 13px;
    font-family: "Poppins", sans-serif;

    font-weight: 500;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .dropdown>.shipment-dropdown>.mat-select-value {
    margin-left: 0px !important;
  }

  .dropdown1>.form-group>.form-control-wapper .Additional-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
  }

  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .shipment-red {
    float: left;
    width: 11% !important;
    top: 3px;
    position: relative;
  }

  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 10px 28px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .shipping-img {
    background-image: url(./assets/images/side-img-ship.svg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .sideImg {
    text-align: right;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    bottom: 0px;
    position: relative;
    top: 33px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 400;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden!important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 455px;
    padding-right: 17px;
  }

  .watch-video {
    padding-right: 15px;
  }

  video {
    width: 100%;
    height: 100%;
    height: 416px;
    object-fit: cover;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 30px 18px 0px 50px;
  }

  /*client dahsbaord css*/
  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    img.ng-star-inserted {
      // width: 80%;
    }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 12px 0px 0px 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 15px 14px;
          margin: 0px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #c3c3c3;
          width: 100%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #586268;
            border-left: 4px solid #f8a23a !important;
            border-top: 0;
            border-bottom: 0;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ul-dash>li:nth-of-type(4) {
    border-top: 1px solid #5d676d;
  }

  .ul-dash>li:nth-of-type(6) {
    border-top: 1px solid #5d676d;
  }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 14px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 14px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    float: right;
  }

  ul.ul-dash {
    margin-top: 0px;
    margin: 0px;
  }

  .dropdown {
    height: 43px;
    background-color: #fff;
    margin-top: 16px;
    width: 232px;
    margin: 13px 8px 11px;
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
  }

  span.shipment-red>img {
    width: 90%;
  }

  .mat-icon {
    position: relative;
    left: -34px;
    color: #d1373e;
    /* top: -35px; */
    bottom: 148px;
  }
}

@media only screen and (max-width: 1438px) and (min-width: 1366px) {
  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .mobile-icon-shipment {
    display: none;
  }

  img.invoice-download {
    width: 38%;
  }

  .mat-drawer {
    scrollbar-width: none;
  }

  .mat-drawer-content {
    scrollbar-width: none;
    margin-left: 250px !important;
  }

  #dashboad-ul {
    height: 100%;
    width: 100%;
    overflow: hidden;
    scrollbar-width: none;
  }

  #dashboad-ul1 {
    width: 100%;
    height: 99%;
    overflow: auto;
    scrollbar-width: none;
  }

  .mat-icon {
    position: absolute;
    left: 235px;
    color: #d1373e;
    top: -6px;
    bottom: 0px;
    display: flex;
    float: right;
  }

  .shipping-drop {
    font-size: 13px;
    font-family: "Poppins", sans-serif;

    font-weight: 500;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 10px;
  }

  .dropdown>.shipment-dropdown>.mat-select-value {
    margin-left: 0px !important;
  }

  .dropdown1>.form-group>.form-control-wapper .Additional-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
  }

  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .shipment-red {
    float: left;
    width: 11% !important;
    top: 3px;

    position: relative;
  }

  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 10px 27px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .shipping-img {
    background-image: url(./assets/images/side-img-ship.svg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .sideImg {
    text-align: right;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    bottom: 0px;
    position: relative;
    top: 33px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 400;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden!important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 455px;
    padding-right: 17px;
  }

  .watch-video {
    padding-right: 15px;
  }

  video {
    width: 100%;
    height: 100%;
    height: 416px;
    object-fit: cover;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 30px 18px 0px 50px;
  }

  /*client dahsbaord css*/
  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    &.create-btn.green {
      background: transparent linear-gradient(270deg, #33d033 0%, #5cd53e 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    &.create-btn.mark {
      // background: transparent linear-gradient(270deg, #195619 0%, #73b762 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(270deg, green 0%, green 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    // img.ng-star-inserted {
    //   // width: 80%;
    // }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 12px 0px 0px 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 10px 14px;
          margin: 0px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #c3c3c3;
          width: 100%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #586268;
            border-left: 4px solid #f8a23a !important;
            border-top: 0;
            border-bottom: 0;
            padding: 15px 10px;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ul-dash>li:nth-of-type(4) {
    border-top: 1px solid #5d676d;
  }

  .ul-dash>li:nth-of-type(6) {
    border-top: 1px solid #5d676d;
  }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 14px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 14px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    float: right;
  }

  ul.ul-dash {
    margin-top: 0px;
    margin: 0px;
  }

  .dropdown {
    height: 43px;
    background-color: #fff;
    margin-top: 16px;
    width: 232px;
    margin: 13px 8px 11px;
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
  }

  span.shipment-red>img {
    width: 90%;
  }
}

@media only screen and (max-width: 1365px) and (min-width: 1000px) {
  .card-list>ul>li {
    font-size: 10px;
    padding: 7px 0px 7px;
    border-top: none;
    position: relative;
    color: rgba(0, 0, 0, 0.7215686275);
  }

  .virtual-account {
    font-size: 13px;
  }

  .shipping-img {
    background-image: url(./assets/images/side-img-ship.svg);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .sideImg {
    text-align: right;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    bottom: 0px;
    position: relative;
    top: 33px;
  }

  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .download-icon {
    width: 150px;
    height: 40px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 5px;
  }

  .mobile-icon-shipment {
    display: none;
  }

  .card-detail {
    border-radius: 11px 11px 0px 0px;
    height: 80px;
    padding: 12px 16px;
  }

  img.invoice-download {
    width: 38%;
  }

  #dashboad-ul {
    overflow: hidden;
  }

  #dashboad-ul1 {
    overflow: auto;
  }

  .inbox-card-mailbox {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 14px;
    padding: 20px 20px 0px;
    height: 350px;
    min-height: 450px;
    overflow: auto;
  }

  button.theme-btn.white-btn.back-red.bulk-shipment {
    font-size: 12px;
    margin-top: 5px;
    width: 150px;
    height: 40px;
  }

  .theme-btn.orange.create-shipment {
    font-size: 12px;
    width: 151px;
    height: 40px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 42px;
    right: 46px;
    float: right;
  }

  .fillters>form-field {
    font-size: 13px;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 66%;
    margin-left: 29px;
  }

  .mat-icon {
    position: relative;
    left: -34px;
    color: #d1373e;
    /* top: -35px; */
    bottom: 126px;
  }

  .open-scan {
    display: inline-block;
    background: #f684ad 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 20px;
    color: #fff;
    margin-bottom: 0px;
    // width: 48%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  .Forward {
    display: inline-block;
    background: #3d9eff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 23px;
    color: #fff;
    width: 46%;
    text-align: center;
    font-size: 14px;
    height: 45px;
    margin-bottom: 10px;
  }

  .hold {
    display: inline-block;
    background: #add841 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 21px;
    color: #fff;
    margin-bottom: 0px;
    width: 47%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  .mail {
    display: inline-block;
    background: #9266ec 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 22px;
    color: #fff;
    margin-bottom: 0px;
    width: 47%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .shipment-red {
    float: left;
    width: 11% !important;
    top: 3px;
    position: relative;
  }

  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 457px !important;
  }

  .form {
    display: flex;
    flex-direction: column;

    margin: 35px 50px 0px 50px;
  }

  .text-center.cursor.dont {
    margin-top: 30px;
    margin-bottom: 26px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 400;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 450px;
    padding-right: 17px;
  }

  .watch-video {
    padding-right: 15px;
  }

  video {
    width: 100%;
    height: 100%;
    /* background: black; */
    height: 416px;
    object-fit: cover;
  }

  .form {
    // width: 500px;
    // margin-top: 90px;

    display: flex;
    flex-direction: column;

    margin: 30px 50px 0px 50px;
  }

  .font-22 {
    font-size: 16px;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 20px 16px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .mailBox-icon {
    position: absolute;
    top: -20px;
    width: 50px;
    right: 10px;
  }

  .shipping-icon {
    position: absolute;
    top: -21px;
    width: 50px !important;
    right: 10px;
  }

  .card-list>ul {
    padding: 0px 18px 0px;
  }

  /*client dahsbaord css*/

  .logo1 {
    background-color: #eeefef;
    padding: 5px 16px;
    height: 63px;
  }

  .logo1 {
    top: 20px;
    right: 80px;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;

    &.create-btn.red {
      background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      box-shadow: 0px 8px 10px rgb(208 51 58 / 20%);
      font-size: 14px;
    }

    span.ship>img {
      width: 8%;
      position: relative;
      text-align: center;
      justify-content: center;
      display: inline-block;
      top: 3px;
      margin-left: 10px;
    }
  }

  span.Dashbaord {
    font-weight: 600;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;

    & p {
      font-size: 12px;
      justify-content: center;
      display: block;
      padding: 9px;
      color: #fff8f83d;
      display: flex;
    }
  }

  footer.footer-bottom>p>span>img {
    margin-right: 15px;
    justify-content: center;
    text-align: center;
    padding: 3px 0px 0px 0px;
  }

  .help-btn img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  button.contact {
    background-color: #fff0;
    border: 1px solid #d0333a;
    padding: 9px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  span.james {
    margin-left: 11px;
  }

  .main {
    height: 100vh;
  }

  .side-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    height: 100%;
    background-color: #303c42;
    transition: 0.5s ease-in-out;

    &.sm-width {
      width: 60px;
      transition: 0.5s ease-in-out;
    }

    // img.ng-star-inserted {
    //   width: 80%;
    // }

    .logo {
      background-color: #eeefef;
      padding: 5px 16px;
      height: 63px;
    }

    .logs {
      padding: 9px 12px;
    }

    .logoImg {
      width: 150px;
    }

    section {
      flex: 1;
      height: 100%;
      overflow: auto;

      // overflow-y: scroll;
      ul {
        flex-direction: column;
        padding: 0px;

        li {
          list-style: none;
          font-weight: 500;
          padding: 12px 14px;
          margin: 0px 0px;
          font-size: 13px;
          position: relative;
          z-index: 1;
          color: #c3c3c3;
          width: 100%;

          // border-left: 4px solid transparent !important;
          // border-top: 0;
          // border-bottom: 0;
          div img {
            width: 15px;
            height: 15px;
            display: block;
          }

          div .navicon-active {
            display: none;
          }

          &.hidden {
            display: none;
          }

          &.active {
            color: #fff;
            background: #586268;
            border-left: 4px solid #f8a23a !important;
            border-top: 0;
            border-bottom: 0;
            padding: 15px 10px;

            >div .navicon {
              display: none !important;
            }

            >div .navicon-active {
              display: block !important;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .userImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  .panel-header>span {
    font-size: 14px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
      0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
      0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
      0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
  }

  .getStarted {
    color: #d0333a;
  }

  .topbar {
    background-color: #201f23;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.88;
  }

  .header-logo img {
    width: 190px;
    height: 50px;
  }

  .details {
    margin-right: 5px;
  }

  .userpik {
    margin-left: 5px;
  }

  .passwordChange {
    padding: 10px 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .logoutUser {
    padding: 10px 12px;
    background: rgb(223 18 57 / 90%);
    border-radius: 0px 0px 5px 5px;
    color: #fff;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  img.adminicon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #f09030;
  }

  img.admin-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #ccc9c9;
  }

  .popupMenu {
    position: relative;
  }

  .userName {
    color: #474849 !important;
    font-size: 18px;
  }

  .popupArrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    top: -4px;
    right: 60px;
    z-index: -1;
    box-shadow: 0px 0px 10px #d9cbcb73;
  }

  .user-profile {
    width: 91%;
    margin-left: auto;

    div {
      &:first-child {
        width: 27%;
        border-right: 1px solid;

        img {
          width: 36px;
        }
      }
    }
  }

  :host::ng-deep .mat-menu-panel {
    border-radius: 10px !important;
  }

  .bg-img>img {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    float: right;
  }

  ul.ul-dash {
    margin-top: 0px;
    margin: 0px;
  }

  .dropdown {
    height: 45px;
    background-color: #fff;
    margin-top: 16px;
    width: 230px;
    margin: 16px 10px 16px;
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
  }

  span.shipment-red>img {
    width: 90%;
  }
}

@media only screen and (max-width: 999px) and (min-width: 886px) {
  .mobile-icon-shipment {
    padding: 20px;
  }

  .dropdown>.shipment-dropdown .mat-select-placeholder {
    color: #d0333a !important;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 30px 50px 0px 35px !important;
  }

  .font-22 {
    font-size: 16px;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 5%);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 20px 16px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .shipping-icon[_ngcontent-wwa-c287] {
    position: absolute;
    top: -21px;
    width: 50px !important;
    right: 10px;
  }

  .card-list>ul {
    padding: 0px 18px 0px;
  }

  .getStarted {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 400;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    // overflow: hidden!important;
  }

  .main-padding {
    padding: 20px 0px 0px;
  }

  img.img-fluid.d-block.mx-auto {
    position: absolute;
    z-index: 120;
    height: 450px;
    padding-right: 17px;
  }

  .watch-video {
    padding-right: 15px;
  }

  video {
    width: 100%;
    height: 100%;
    /* background: black; */
    height: 416px;
    object-fit: cover;
  }

  .form {
    // width: 500px;
    // margin-top: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 50px 0px 50px;
  }

  /*Mobile Rtesponisve Css*/

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  }
}

@media only screen and (max-width: 1166px) {
  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .Filter-status {
    margin-left: 10px;
  }

  .filter-icon {
    position: absolute;
    left: 18px;
    top: 18px;
    height: 10px;
  }

  .mobile-icon-shipment {
    display: none;
  }

  .open-scan {
    display: inline-block;
    background: #f684ad 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 12px;
    color: #fff;
    margin-bottom: 0px;
    // width: 48%;
    text-align: center;
    font-size: 13px;
    height: 45px;
  }

  .Forward {
    display: inline-block;
    background: #3d9eff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 20px;
    color: #fff;
    width: 46%;
    text-align: center;
    font-size: 13px;
    height: 45px;
    margin-bottom: 10px;
  }

  .hold {
    display: inline-block;
    background: #add841 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 13px;
    color: #fff;
    margin-bottom: 0px;
    width: 47%;
    text-align: center;
    font-size: 13px;
    height: 45px;
  }

  .mail {
    display: inline-block;
    background: #9266ec 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 15px;
    color: #fff;
    margin-bottom: 0px;
    width: 47%;
    text-align: center;
    font-size: 13px;
    height: 45px;
  }

  table.table.table-responsive.address-table.packages-table>tr>td:nth-child(1) {
    width: 19%;
  }

  .mat-icon {
    position: relative;
    left: -34px;
    color: #d1373e;
    /* top: -35px; */
    bottom: 142px;
  }

  .pakc {
    margin-right: 0px !important;
    // display: inline-block;
    padding-right: -3px;
    float: right;
  }

  .theme-btn.orange.create-shipment {
    font-size: 13px;
    width: 148px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    padding-right: 6px;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .tble-res {
    overflow-x: auto;
  }

  .hidden-lg-up {
    display: none !important;
  }

  .selsect-date {
    border: 1px solid #afb0c9;
    border-radius: 10px;
    padding: 6px 3px 10px 5px;
    width: 280px;
  }

  .estimated-arrival {
    font-size: 10px;
    bottom: 5px;
    position: relative;
  }

  .dayService>span {
    font-size: 13px;
  }

  .dayprice p {
    font-size: 18px;
    font-weight: 500;
  }

  .card-detail {
    border-radius: 11px 11px 0px 0px;
    height: 67px;
    padding: 12px 13px;
  }

  span.file-icon>img {
    width: 23px;
  }

  .download-icon {
    width: 100px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  span.file-icon {
    background-color: #cbcbcb;
    width: 47px;
    height: 37px;
    display: inline-block;
    border-radius: 24px 0px 0px 24px;
    padding: 6px;
    text-align: center;
  }

  span.don-icon>img {
    width: 21px;
  }

  ul.icon-indiftication {
    padding: 0px;
    margin: 0;
    display: flex;
    margin-right: 0px;
    padding-right: 0px;
    float: left;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 86%;
    margin-left: 36px;
  }

  .col-lg-6.col-xl-3.justify-center.pr-2.butn-display {
    margin-bottom: 8px;
    float: left;
    display: flex;
    margin-left: 0px;
    left: 14px;
    justify-content: left !important;
  }

  .col-lg-6.col-xl-3.justify-center.ml-auto.pr-2.addres-list {
    float: right;
    justify-content: end;
    display: contents;
    justify-content: right;
  }
}

@media only screen and (max-width: 1024px) {
  .chek-div {
    margin-bottom: 20px;
  }

  .col-lg-1.check-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 4%;
    max-width: 7%;
    margin-right: 9px;
  }

  .col-lg-6.col-xl-3.justify-center.ml-auto.pr-2.addres-list {
    float: right;
    justify-content: end;
    display: contents;
    justify-content: right;
  }

  .mat-icon {
    position: relative;
    left: -34px;
    color: #d1373e;
    /* top: -35px; */
    bottom: 119px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 72%;
    margin-left: 23px;
  }

  span.file-icon>img {
    width: 23px;
  }

  span.don-icon>img {
    width: 21px;
  }

  span.file-icon {
    background-color: #cbcbcb;
    width: 47px;
    height: 37px;
    display: inline-block;
    border-radius: 24px 0px 0px 24px;
    padding: 6px;
    text-align: center;
  }

  .download-icon {
    width: 97px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 5px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 97px;
    right: 30px;
    float: right;
  }

  .open-scan {
    display: inline-block;
    background: #f684ad 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 23px;
    color: #fff;
    margin-bottom: 5px;
    // width: 100%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  .Forward {
    display: inline-block;
    background: #3d9eff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 23px;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 14px;
    height: 45px;
    margin-bottom: 5px;
  }

  .hold {
    display: inline-block;
    background: #add841 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 23px;
    color: #fff;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  .mail {
    display: inline-block;
    background: #9266ec 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 86px;
    padding: 10px 23px;
    color: #fff;
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    height: 45px;
  }

  // .inner-dashbaord {
  //   padding: 2px;
  // }

  .open-scan>p>img {
    float: left;
    width: 20px;
    margin-right: 15px;
  }

  .Forward>p>img {
    float: left;
    width: 10%;
    margin-right: 15px;
  }

  .hold>p>img {
    float: left;
    width: 10%;
    margin-right: 15px;
  }

  .mail>p>img {
    float: left;
    width: 10%;
    margin-right: 15px;
    margin-top: 5px;
  }

  .shipment-div {
    width: 100%;
    display: grid;
    text-align: left;
    padding: 10px 14px;
  }

  .img-ship1>img {
    float: right;
    padding: 7px;
    width: 13%;
    margin-top: 13px;
    margin-right: 7px;
  }

  .img-ship>img {
    float: right;
    padding: 6px;
    width: 13%;
  }

  .create-mailbox-dash {
    height: 130px;
    background: #e84393 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 0px 0px 17px;
  }

  .edit-servces.ship-detail {
    display: flex;
  }

  .perc {
    margin-left: 1px;
  }

  .genrate.perc {
    margin-left: 1px;
  }

  .Percentage-icon {
    position: absolute;
    right: 15px;
    bottom: 19px;
  }

  .col-lg-3.perc {
    max-width: 30% !important;
    flex: 30%;
  }

  table.table.table-responsive.address-table.packages-table>tr>td:nth-child(1) {
    width: 19%;
  }

  .pakc {
    margin-right: 5px !important;
    display: inline-block;
    padding-right: -3px;
    float: right;
    margin-bottom: 12px;
  }

  .theme-btn.orange.create-shipment {
    font-size: 13px;
    width: 148px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    padding-right: 6px;
    margin-left: 10px !important;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 25px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .tble-res {
    overflow-x: auto;
  }

  .hidden-lg-up {
    display: none !important;
  }

  .selsect-date {
    border: 1px solid #afb0c9;
    border-radius: 10px;
    padding: 6px 3px 10px 5px;
    width: 280px;
  }

  .estimated-arrival {
    font-size: 10px;
    bottom: 5px;
    position: relative;
  }

  .dayService>span {
    font-size: 13px;
  }

  .dayprice p {
    font-size: 18px;
    font-weight: 500;
  }

  .card-detail {
    border-radius: 11px 11px 0px 0px;
    height: 67px;
    padding: 12px 13px;
  }
}

@media only screen and (max-width: 992px) {
  .searching-icon1 {
    position: absolute;
    right: 19px;
    top: 18px;
    height: 14px;
  }

  .help-search {
    margin: 0px 20px;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-4.cards-div {
    width: 33%;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .formailtext {
    padding-left: 0px !important;
    padding-right: 0rem !important;
  }

  .hold-img>img {
    width: 100%;
  }

  .col-lg-4.col-xl-3.pr-2.serach {
    margin-left: 8px;
  }

  .hidden-lg-up {
    display: block !important;
  }

  .mat-expansion-panel-body {
    padding: 0 32px 16px;
    height: 121px !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  table.table.table-responsive.address-table.packages-table>tr>td:nth-child(1) {
    width: 23%;
  }

  table.table.table-responsive.address-table.packages-table>tr>td:nth-child(2) {
    width: 20%;
  }

  table.table.table-responsive.address-table.packages-table>tr>td:nth-child(3) {
    width: 23%;
  }

  .label-no {
    display: none;
  }

  .col-lg-5.cler.float-right {
    position: absolute;
    right: 45px;
  }

  img.searching-icon {
    position: absolute;
    right: 21px;
    top: 18px;
    height: 14px;
  }

  .bg-info {
    background-image: url(./assets/images/bg-img.svg);
    background-size: 160%;
    background-repeat: no-repeat;
    padding: 20px 0px 0px;
    /* object-fit: contain; */
    /* height: 100vh; */
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
  }

  .download-icon {
    width: 96px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .padding-both-side {
    padding: 10px;
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;
  }

  .passwordChange {
    padding: 10px 12px;
  }

  .passwordChange {
    font-size: 14px;
  }

  .col-lg-1.d-flex.order-3.close-file {
    justify-content: end;
    margin-right: 21px;
  }

  img.search-icon {
    position: absolute;
    right: 17px;
    top: 49px;
    height: 14px;
  }

  .col-lg-6.mt-3.order-2.create-ship {
    margin-top: 0px !important;
  }

  .getStarted {
    color: #d0333a;
  }

  .delay1 {
    padding: 20px 0px 10px 0px;
  }

  .delay2 {
    padding: 10px 0px 10px 0px;
  }

  .delay3 {
    padding: 10px 0px 10px 0px;
  }

  .delay4 {
    padding: 10px 0px 10px 0px;
  }

  .delay5 {
    padding: 10px 0px 10px 0px;
  }

  .delay6 {
    padding: 10px 0px 10px 0px;
  }

  .delay7 {
    padding: 10px 0px 10px 0px;
  }

  .delay8 {
    padding: 10px 0px 10px 0px;
  }

  .create-mailbox-dash {
    height: 130px;
    background: #e84393 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .archive-mailbox-dash {
    height: 130px;
    background: #ff7675 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .trash-mailbox-dash {
    height: 130px;
    background: #00b894 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .create-shipment-dash {
    height: 130px;
    background: #e44531 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .pervious-shipment-dash {
    height: 130px;
    background: #fd79a8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .draft-shipment-dash {
    height: 130px;
    background: #00cec9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .card.saving.white-form-card>img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }

  .dropdown {
    display: none;
  }

  button.contact {
    display: none;
  }

  // button.tutorial {
  //   display: block;
  // }
  button.tutorial {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 115px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
    display: block;
  }

  .hidden-lg-up {
    margin-left: 0px;
    display: block;
  }

  .sm-width {
    width: 60px;
    transition: 0.5s ease-in-out;
  }

  .md-width {
    margin-left: 57px !important;
  }

  .bg-img>img {
    width: 100%;
  }

  .footer-bottom p {
    font-size: 12px;
    justify-content: center;
    display: block;
    padding: 9px;
    color: rgba(255, 248, 248, 0.2392156863);
    display: flex;
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;
    display: none;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 86px;
    width: 88px;
    height: 30px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 1px 6px 0px 0px;
    margin: 5px 10px 6px 0px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .options {
    position: absolute;
    bottom: 44px;
    left: 0;
    right: 0;
    text-align: center;
  }

  //   .dropdown {
  //     height: 43px;
  //     background-color: #fff;
  //     margin-top: 16px;
  //     width: 218px;
  //     margin: 13px 6px 11px;
  //     border-radius: 5px;
  //     display: inline-block;
  //     padding: 10px;
  // }
  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .side-container section ul {
    flex-direction: column;
    padding: 0px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 10px;
    height: 62px;
  }

  // img.ng-star-inserted {
  //   // width: 40px;
  //   height: 45px;
  //   margin-top: 4px;
  // }

  button.contact {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 110px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
  }

  .side-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #303c42;
    transition: 0.5s ease-in-out;
    min-height: 443px;
    overflow-y: overlay;

    // overflow-y: overlay;
    // position: fixed;
  }

  .side-container section ul li {
    list-style: none;
    font-weight: 500;
    padding: 15px 24px;
    margin: 0px 0px;
    font-size: 13px;
    position: relative;
    z-index: 1;
    color: #c3c3c3;
    width: 100%;
  }


  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  /*Mobile Rtesponisve Css*/
  .header-btn {
    color: #fff;
    width: 40px;
    margin-left: 34px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  }


  .side-container section ul li div .navicon-active {
    display: none;
  }

  .side-container section ul li div img {
    width: 15px;
    height: 15px;
    display: block;
  }

  .table-responsive {
    width: 100%;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  img.form-append.mr-2 {
    width: 12px;
    justify-content: end;
    display: inline-block;

    // margin-top: 13px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 92.8%;
    margin-left: 29px;
  }

  .filter-date {
    margin-left: 8px;
    margin-bottom: 8px;
    padding: 6px 0px 0px 6px;
  }

  .Filter-status {
    margin-left: 8px;
    margin-top: 0px;
  }

  .col-lg-3.pr-2.serach {
    margin-left: 9px;
  }

  button.theme-btn.white-btn.back-red.bulk-shipment {
    font-size: 13px;
    margin-top: 5px;
    width: 150px;
    height: 40px;
    margin-left: 10px;
  }

  .butn-display {
    margin-bottom: 11px;
  }

  .filter-icon {
    position: absolute;
    left: 17px;
    top: 18px;
    height: 10px;
  }

  .tble-res {
    overflow-x: auto;
  }

  span.james.font-14 {
    display: none;
  }

  .img-ship>img {
    float: right;
    padding: 10px;
    width: 6%;
  }

  .create-btn.create-btn.red {
    background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    box-shadow: 0px 8px 10px rgba(208, 51, 58, 0.2);
    font-size: 14px;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 280px;
    height: 39px;
    cursor: pointer;
  }

  .create-btn span.ship>img {
    width: 8%;
    position: relative;
    text-align: center;
    justify-content: center;
    display: inline-block;
    top: 3px;
    margin-left: 10px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  .shipment-dashboard {
    padding: 17px;
  }

  .graph-t {
    padding: 18px 10px 0px 20px;
  }

  .graph-r {
    padding: 18px 10px 0px 20px;
  }

  span.Dashbaord {
    margin-left: 20px;
  }

  .inbox-img>img {
    width: 100%;
    height: 100%;
  }

  .Forward>p>img {
    float: left;
    width: 4%;
    margin-right: 15px;
  }

  .hold>p>img {
    float: left;
    width: 4%;
    margin-right: 15px;
  }

  .mail>p>img {
    float: left;
    width: 4%;
    margin-right: 15px;
    margin-top: 3px;
  }

  .open-scan>p>img {
    float: left;
    width: 20px;
    margin-right: 15px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 19px;
    right: 19px;
    float: right;
  }

  a.help-btn>span {
    display: none;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 86px;
    width: 45px;
    height: 45px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .options {
    position: relative;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    display: block;
  }

  .help-btn {
    padding: 10px 6px 0px 6px;
    margin: 8px 12px 12px 7px;
  }

  .padding-m {
    padding: 30px;
  }

  .order-3 {
    order: 2;
    bottom: 82px;
  }

  .order-2 {
    order: 3;
  }

  .pr-3 {
    padding-right: 0rem !important;
  }

  .img-ship1>img {
    float: right;
    padding: 10px;
    width: 6%;
    margin-top: 13px;
    margin-right: 7px;
  }

  .basic-plan {
    margin: 0px 17px 20px 17px;
  }

  .stand-plan {
    margin: 0px 17px 20px 17px;
  }

  .pre-plan {
    margin: 0px 17px 20px 17px;
  }

  .card-div.visa {
    width: 67%;
  }

  .col-lg-3.pr-2.search.padding-l {
    padding-left: 10px;
  }

  .row.justify-center.padding-both {
    padding: 50px;
  }

  .padding-both {
    padding: 20px;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 10px 28px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .white-form-card.log-white-card.carrir {
    height: 390px;
  }

  .logo-img {
    height: 49%;
    min-height: 58%;
  }

  // .form.form-width {
  //   width: 84%;
  // }

  // video.video-tag {
  //   position: absolute;
  // }

  // .form-login {
  //   display: flex;
  //   top: 110px;
  //   bottom: 10px;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   z-index: 999;
  //   border-radius: 20px;
  //   max-width: 70%;
  //   width: 100%;
  //   height: auto;
  //   overflow: scroll;
  //   background-color: #fff;
  // }

  .form {
    display: flex;
    flex-direction: column;
    margin: 30px 18px 0px 50px;
  }

  .Filter-status {
    width: 99%;
  }

  .inbox-card-mailbox {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 14px;
    padding: 20px 20px 0px;
    height: 100%;
    min-height: 450px;
    overflow: hidden;
  }

  .col-lg-1.col-xl-3.re {
    display: inline-block;
    width: 20%;
  }

  .images-upload {
    background-color: #fff;
    padding: 32px 0px 0px;
    width: 101px;
    height: 98px;
    border-radius: 30px;
    /* text-align: center; */
    margin: 0 75px 23px;
    display: inline-block;
  }

  .images-upload>img {
    width: 43%;
  }

  .icons-packages {
    padding: 4px;
    text-align: right;
    float: right;
  }

  .cler {
    display: inline-block;
    margin-bottom: 0px !important;
    position: absolute;
    right: 40px;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  .side-container section ul li ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .col-lg-6.col-xl-3.justify-center.pr-2.butn-display {
    margin-bottom: 8px;
    float: left;
    display: flex;
    margin-left: 0px;
    left: 0px;
    justify-content: left !important;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-4.cards-div {
    width: 33%;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .formailtext {
    padding-left: 0px !important;
    padding-right: 0rem !important;
  }

  .help-search {
    margin: 0px 20px;
  }

  .hold-img>img {
    width: 100%;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  .icons-innovices>img {
    width: 72%;
    margin-right: 7px;
  }

  .download-icon {
    width: 96px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 3px;
    margin-bottom: 10px;
  }

  .mobile-serach {
    margin: 0px 0px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 91.9%;
    margin-left: 29px;
  }

  span.Dashbaord {
    margin-left: 0px;
  }

  .Filter-status {
    width: 99%;
  }

  .account-setting {
    padding-left: 0px;
  }

  .Percentage-icon {
    position: absolute;
    right: 15px;
    bottom: 19px;
  }

  .tab-unpaid.profile-tab {
    padding: 0px 20px 0px 19px;
  }

  .cler {
    display: inline-block;
    margin-bottom: 0px !important;
    position: absolute;
    right: 40px;
  }
}

@media only screen and (max-width: 667px) {
  .create-shipment-dash {
    height: 130px;
    background: #e44531 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 15px;
  }

  .pervious-shipment-dash {
    height: 130px;
    background: #fd79a8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 15px;
  }

  .draft-shipment-dash {
    height: 130px;
    background: #00cec9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 15px;
  }

  .graph-t {
    padding: 18px 10px 0px 15px;
  }

  .graph-r {
    padding: 18px 10px 0px 15px;
  }

  .delay1 {
    padding: 20px 10px 10px 15px;
  }

  .delay2 {
    padding: 10px 10px 10px 15px;
  }

  .delay3 {
    padding: 10px 10px 10px 15px;
  }

  .delay4 {
    padding: 10px 10px 10px 15px;
  }

  .delay5 {
    padding: 10px 10px 10px 15px;
  }

  .img-ship>img {
    float: right;
    padding: 10px;
    width: 7%;
  }

  .text-center.font-30.fw-6.shipment-id {
    font-size: 20px;
    display: grid;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-4.cards-div {
    width: 33%;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .formailtext {
    padding-left: 0px !important;
    padding-right: 0rem !important;
  }

  .help-search {
    margin: 0px 20px;
  }

  .hold-img>img {
    width: 100%;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  .icons-innovices>img {
    width: 72%;
    margin-right: 7px;
  }

  .download-icon {
    width: 96px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 12px;
    padding: 2px;
    margin-top: 3px;
    margin-bottom: 10px;
  }

  // .col-lg-6.col-xl-3.pr-2.pl-2.padding-l.mobile-serach {
  //   padding-right: 0rem !important;
  // }
  // .mobile-serach {
  //   margin: 0px 8px;
  // }
  .cler {
    display: inline-block;
    margin-bottom: 0px !important;
    position: absolute;
    right: 40px;
  }

  .hold-img>img {
    width: 100%;
  }

  .icons-packages {
    padding: 2px;
    text-align: right;
    float: right;
  }

  .images-upload {
    background-color: #fff;
    padding: 57px 0px 0px;
    /* width: 181px; */
    height: 180px;
    border-radius: 30px;
    text-align: center;
    margin: 0 92px 23px;
  }

  .images-upload>img {
    width: 23%;
  }

  .Filter-status {
    width: 98.5%;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 30px 18px 0px 35px;
  }

  .padding-both-side {
    padding: 10px;
  }

  img.img-logo-1 {
    margin-left: 12px;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 10px 28px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .padding-both {
    padding: 20px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 91%;
    margin-left: 25px;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 29px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .col-lg-3.pr-2.pl-2.padding-l {
    padding-left: 7px !important;
  }

  .pay-success>img {
    width: 100%;
  }

  // .pl-2 {
  //   padding-left: 0 !important;
  // }

  .ng-otp-input-wrapper .otp-input:not(:last-child) {
    margin-right: 3px !important;
  }

  // .pl-2 {
  //   padding-left: 0 !important;
  // }

  .padding-r {
    padding-right: 20px;
  }

  .col-lg-3.pr-2.search.padding-l {
    padding-left: 10px;
  }

  .row.justify-center.padding-both {
    padding: 50px;
  }

  .white-form-card.log-white-card.carrir {
    height: 400px;
  }

  .logo-img {
    height: 38%;
    min-height: 58%;
  }

  .col-lg-6.padding-both.mobile-top {
    padding: 15px 15px 0px;
    margin-bottom: 0px;
  }

  .col-lg-6.padding-both.mobile-shipment {
    padding: 15px 15px 0px;
  }
}

@media only screen and (max-width: 540px) {
  .id-card {
    height: 75%;
  }

  button.theme-btn.red.sent {
    margin: 10px 0px;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .formailtext {
    padding-left: 0px !important;
    padding-right: 0rem !important;
  }

  .help-search {
    margin: 0px 20px;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  .icons-innovices>img {
    width: 72%;
    margin-right: 7px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 91%;
    margin-left: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .desk {
    display: none;
  }

  .mob {
    display: none;
  }

  .contactBtn {
    position: static;
  }
}

@media only screen and (min-width: 501px) {
  .desk {
    display: block;
  }

  .mob {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .dailogClass {
    padding: 0px 10px !important;
  }

  .upload {
    padding: 0px 10px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 32px 16px;
    height: auto !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .virtual-account {
    font-size: 16px;
  }

  .col-lg-6.col-sm-6.pr-2.margin-top.margin-bottom {
    margin-bottom: 20px;
  }

  .Card.shippment-form-card.pickup {
    margin: 19px;
    padding: 20px;
  }

  .text-center.font-30.fw-6.shipment-id {
    margin-top: 62px;
    font-size: 18px;
    display: grid;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-4.cards-div {
    width: 100%;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .col-lg-3.pr-3.py-3.arrival-shipment {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  .help-search {
    margin: 0px 20px;
  }

  .searching-icon1 {
    position: absolute;
    right: 26px;
    top: 18px;
    height: 14px;
  }

  .col-lg-6.col-xl-3.justify-center.pr-2.butn-display {
    margin-bottom: 8px;
    float: left;
    display: flex;
    margin-left: 0px;
    left: 0px;
    justify-content: left !important;
  }

  .inner-dashbaord {
    padding: 28px 9px 0px 9px !important;
  }

  .delay1 {
    padding: 20px 10px 10px 20px;
  }

  .col-lg-4.mobile-padding {
    padding: 20px;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  .mobile-serach {
    margin: 0px 8px;
  }

  .mobile-top {
    margin-top: 60px;
  }

  .col-lg-5.inbox-padding {
    padding: 10px;
  }

  .col-lg-4.col-xl-3.pr-2.serach {
    padding-left: 8px;
  }

  .shippment-delivery.admin>p {
    margin-bottom: 5px;
    color: #303c42;
    font-weight: 500 !important;
    font-size: 12px;
  }

  .shippment-delivery.admin {
    margin-top: 8px;
    border-radius: 18px;
    border: 1px solid #cecece;
    background-color: #fbfcff;
    padding: 10px 15px 5px;
    width: 260px;
  }

  .col-lg-6.padding-both.mobile-top {
    padding: 15px 15px 0px;
    margin-bottom: 0px;
  }

  .col-lg-6.padding-both.mobile-shipment {
    padding: 15px 15px 0px;
  }

  .icons-packages>img {
    width: auto;
  }

  .col-lg-12.pt-2.blur-open>p {
    text-align: center;
  }

  .col-lg-12.justify-center.mobile-div {
    display: inline-block;
  }

  button.theme-btn.white-btn.mr-2.back-red.back-request {
    width: 100%;
  }

  button.make-change.ml-2.request-btn {
    width: 100%;
    margin: 0px 0px 16px;
    padding: -2px;
    margin-left: 0px !important;
  }

  .cdk-overlay-pane.clickview {
    height: 100% !important;
  }

  .col-lg-1.col-xl-3.re {
    display: inline-block;
    width: 100%;
  }

  .images-upload {
    background-color: #fff;
    padding: 57px 0px 0px;
    width: 223px;
    height: 180px;
    border-radius: 30px;
    text-align: center;
    margin: 0 23px 23px;
  }

  .images-upload>img {
    width: 40%;
  }

  .icons-innovices>img {
    width: 15px;
    margin-right: 7px;
  }

  span.mat-checkbox-label>span {
    width: 92% !important;
    display: contents;
    position: absolute;
  }

  .mat-checkbox {
    width: 100% !important;
  }

  .agree .signup>.mat-checkbox-layout {
    -webkit-user-select: none;
    user-select: none;
    cursor: inherit;
    align-items: baseline;
    vertical-align: middle;
    display: inline-block !important;
    white-space: break-spaces !important;
    width: 100%;
  }

  // video.video-tag {
  //   position: absolute;
  // }

  // .form-login {
  //   display: flex;
  //   top: 138px;
  //   bottom: 10px;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   z-index: 999;
  //   border-radius: 20px;
  //   max-width: 90%;
  //   width: 100%;
  //   height: auto;
  //   background-color: #fff;
  // }

  .form {
    /* display: flex; */
    flex-direction: column;
    margin: 15px 29px 6px 22px;
  }

  .padding-both-side {
    padding: 10px;
  }

  .btn-psot {
    display: block;
  }

  .curior img {
    /* margin-right: 8px; */
    padding: 4px;
    height: 50px;
    object-fit: contain;
  }

  .logo-img {
    height: 72%;
    min-height: 58%;
  }

  .white-form-card.log-white-card.carrir {
    height: 560px;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .white-card {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border: 0.5px solid rgba(208, 51, 58, 0.5);
    border-radius: 10px;
    position: relative;
    padding: 10px 28px 0px;
    height: 100%;
    /* overflow: scroll; */
  }

  .padding-both {
    padding: 20px;
  }

  .row.justify-center.padding-both {
    padding: 50px;
  }

  .send-trash {
    margin-top: 0px !important;
    padding-top: 20px;
  }

  .padding-l {
    padding-left: 10px !important;
  }

  .col-lg-3.pr-2.pl-2.padding-l {
    padding-left: 7px !important;
  }

  .shipping {
    padding: 10px 0px;
  }

  .card-div.visa {
    width: 77%;
  }

  .card {
    margin-bottom: 15px;
  }

  .pay-success>img {
    width: 100%;
  }

  .padding-r {
    padding-right: 20px;
  }

  .h-pickup img {
    width: 80%;
  }

  .main-inbox {
    margin-top: 10px;
  }

  .inbox-card-mailbox {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 14px;
    padding: 20px 2px 0px;
    height: 100%;
    min-height: 450px;
    overflow: auto;
    margin: 0px 11px 0px 6px;
  }

  .logoutUser {
    padding: 10px 12px;
    background: #d0333a;
    border-radius: 0px 0px 5px 5px;
    color: #fff;
  }

  .passwordChange {
    padding: 10px 12px;
  }

  .passwordChange {
    font-size: 14px;
  }

  .create-ship {
    margin-bottom: 30px;
    margin-top: 0px !important;
  }

  .order-3 {
    order: 2;
    bottom: 82px;
  }

  .cler {
    display: inline-block;
    margin-bottom: 0px !important;
    position: absolute;
    right: 40px;
  }

  .cler>p {
    margin-bottom: 0px !important;
  }

  .order-2 {
    order: 3;
  }

  .close-file {
    float: right;
    justify-content: end;
    margin-right: 22px;
    bottom: 55px;
  }

  .padding-m {
    padding: 30px;
  }

  .padding-r {
    padding-right: 15px;
  }

  .searching-icon {
    position: absolute;
    right: 23px !important;
    top: 18px;
    height: 14px;
  }

  .col-lg-3.pr-2.serach {
    margin-left: 10px;
  }

  span.zoom-icon {
    position: absolute;
    bottom: 4px;
    right: 6px;
    float: right;
  }

  .open-scan>p>img {
    float: left;
    width: 20px;
    margin-right: 15px;
  }

  .open-scan {
    width: 100%;
    margin-bottom: 10px;
  }

  .Forward {
    width: 100%;
    margin-bottom: 10px;
  }

  .mail {
    width: 100%;
    margin-bottom: 10px;
  }

  .Forward>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .hold {
    width: 100%;
    margin-bottom: 10px;
  }

  .hold>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
  }

  .mail>p>img {
    float: left;
    width: 11%;
    margin-right: 15px;
    margin-top: 3px;
  }

  image-cropper[_ngcontent-lol-c286] {
    height: 48vh;
  }

  span.zoom-icon>img {
    width: 20px;
    height: 20px;
  }

  .icon {
    background: #7f7f7f 0% 0% no-repeat padding-box;
    border-radius: 86px;
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-left: 13px;
  }

  .shipment-dashboard {
    padding: 17px;
  }

  .create-btn.create-btn.red {
    background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    box-shadow: 0px 8px 10px rgba(208, 51, 58, 0.2);
    font-size: 14px;
  }

  .create-btn span.ship>img {
    width: 8%;
    position: relative;
    text-align: center;
    justify-content: center;
    display: inline-block;
    top: 3px;
    margin-left: 10px;
  }

  .create-btn {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    border-radius: 43px;
    width: 250px;
    height: 39px;
    cursor: pointer;
  }

  .img-ship>img {
    float: right;
    padding: 10px;
    width: 15%;
  }

  .getStarted {
    color: #d0333a;
  }

  .delay2 {
    padding: 10px 10px 10px 20px;
  }

  .delay3 {
    padding: 10px 10px 10px 20px;
  }

  .delay4 {
    padding: 10px 10px 10px 20px;
  }

  .delay5 {
    padding: 10px 10px 10px 20px;
  }

  .delay6 {
    padding: 10px 10px 10px 20px;
  }

  .delay7 {
    padding: 10px 10px 10px 20px;
  }

  .create-mailbox-dash {
    height: 130px;
    background: #e84393 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .archive-mailbox-dash {
    height: 130px;
    background: #ff7675 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .trash-mailbox-dash {
    height: 130px;
    background: #00b894 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .create-shipment-dash {
    height: 130px;
    background: #e44531 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .pervious-shipment-dash {
    height: 130px;
    background: #fd79a8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .draft-shipment-dash {
    height: 130px;
    background: #00cec9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(39, 45, 59, 0.2);
    border-radius: 20px;
    color: #fff;
    margin: 10px 10px 0px 20px;
  }

  .card.saving.white-form-card>img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }

  .dropdown {
    display: none;
  }

  button.contact {
    display: none;
  }

  // button.tutorial {
  //   display: block;
  // }
  button.tutorial {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d0333a;
    padding: 10px;
    border-radius: 50px;
    width: 60px;
    height: 35px;
    margin-right: 30px;
    color: #d0333a;
    display: block;
  }

  .hidden-lg-up {
    margin-left: 0px;
    margin-top: 7px;
  }

  span.Dashbaord {
    // margin-left: 20px;
    font-size: 15px;
  }

  .sm-width {
    width: 60px;
    transition: 0.5s ease-in-out;
  }

  .md-width {
    margin-left: 57px !important;
  }

  .bg-img>img {
    width: 100%;
  }

  .footer-bottom p {
    font-size: 12px;
    justify-content: center;
    display: block;
    padding: 9px;
    color: rgba(255, 248, 248, 0.2392156863);
  }

  .footer-bottom {
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: #586268;
    text-align: center;
    display: none;
  }

  .help-btn {
    border-radius: 50px;
    background: #5d676d 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 86px;
    width: 45px;
    height: 45px;
    text-align: center;
    display: block;
    padding: 7px;
    columns: #fff;
    text-decoration: none;
  }

  .help-btn {
    padding: 10px 6px 0px 6px;
    margin: 8px 12px 12px 7px;
  }

  .help-btn>* {
    vertical-align: middle;
    color: #fff;
    font-size: 12px;
  }

  .options {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    display: block;
  }

  a.help-btn>span {
    display: none;
  }

  //   .dropdown {
  //     height: 43px;
  //     background-color: #fff;
  //     margin-top: 16px;
  //     width: 218px;
  //     margin: 13px 6px 11px;
  //     border-radius: 5px;
  //     display: inline-block;
  //     padding: 10px;
  // }
  .shipment-dropdown {
    width: 80% !important;
    margin-left: 10px;
  }

  .side-container section ul {
    flex-direction: column;
    padding: 0px;
  }

  .logo1 {
    background-color: #eeefef;
    padding: 5px 10px;
    height: 62px;
  }

  img.ng-star-inserted {
    // width: 40px;
    // height: 45px;
    margin-top: 4px;
  }

  button.contact {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d0333a;
    padding: 8px;
    border-radius: 50px;
    width: 60px;
    height: 35px;
    // margin-right: 30px;
    color: #d0333a;
  }

  .side-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #303c42;
    transition: 0.5s ease-in-out;
    min-height: 443px;
    overflow-y: overlay;
    position: fixed;
  }

  .side-container section ul li {
    list-style: none;
    font-weight: 500;
    padding: 22px 24px;
    margin: 0px 0px;
    font-size: 13px;
    position: relative;
    z-index: 1;
    color: #c3c3c3;
    width: 100%;
  }

  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  /*Mobile Rtesponisve Css*/
  .header-btn {
    color: #fff;
    width: 40px;
    margin-left: 34px;
  }

  .panel-header {
    background-color: #fff;
    padding: 14px 24px;
    position: fixed;
    z-index: 999;
    /* position: relative; */
    width: 88%;
    top: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  }

  .side-container section ul li div .navicon-active {
    display: none;
  }

  .side-container section ul li div img {
    width: 15px;
    height: 15px;
    display: block;
  }

  span.file-icon {
    background-color: #cbcbcb;
    width: 46px;
    height: 37px;
    display: inline-block;
    border-radius: 24px 0px 0px 24px;
    padding: 4px;
    text-align: center;
  }

  .table-responsive {
    width: 100%;
  }

  .filter-status1 {
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    // margin-left: 28px !important;
    font-weight: 500;
    width: 100% !important;
    // margin-bottom: 6px;
  }

  .filter-date input {
    border: none;
    font-family: "Poppins", sans-serif;
    width: 83%;
    margin-left: 27px;
    font-weight: 400;
  }

  .filter-date {
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
  }

  .Filter-status {
    margin-left: 8px;
    margin-top: 0px;
    margin-right: 8px;
  }

  .Filter-status {
    width: 96%;
  }

  button.theme-btn.white-btn.back-red.bulk-shipment {
    font-size: 13px;
    margin-top: 5px;
    width: 150px;
    height: 40px;
    margin-left: 10px;
  }

  .butn-display {
    margin-bottom: 11px;
  }

  .filter-icon {
    position: absolute;
    left: 17px;
    top: 18px;
    height: 10px;
  }

  .tble-res {
    overflow-x: auto;
  }

  span.james.font-14 {
    display: none;
  }

  .theme-btn.orange.create-shipment {
    font-size: 13px;
    width: 100%;
    height: 43px;
  }

  span.don-icon {
    width: 40px;
    height: 40px;
    padding: 7px 8px 0px;
    position: absolute;
  }

  .download-icon {
    width: 97px;
    height: 43px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cbcbcb;
    border-radius: 24px;
    margin-left: 7px;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  button.theme-btn.white-btn.back-red.bulk-shipment {
    font-size: 13px;
    margin-top: 5px;
    // width: 100%;
    height: 40px;
    margin-left: 10px;
  }

  .graph-t {
    padding: 18px 10px 0px 20px;
  }

  .graph-r {
    padding: 18px 10px 0px 20px;
  }

  .mat-icon {
    position: absolute;
    left: 251px;
    color: #d1373e;
    top: -5px;
  }

  .white-form-card1.my-cards-div {
    padding: 24px 19px 0px 16px;
  }

  .col-lg-3.offset-lg-2.pr-2.tab-e {
    float: left !important;
    right: 0;
    position: relative;
    justify-content: end;
    display: block;
  }

  .pr-3 {
    padding-right: 0rem !important;
  }

  .addresslist {
    height: 468px !important;
    margin: 0px 10px;
  }

  // .content {
  //   margin-top: 52px;
  // }

  .label-no {
    margin-top: -20px;
    display: block;
  }

  // .col-lg-6.pr-2 {
  //   padding-right: 0rem !important;
  // }

  // .pl-2 {
  //   padding-left: 0 !important;
  // }

  .formmail {
    padding: 14px;
  }

  .white-form-card1.my-cards-div {
    height: 100%;
    /* min-height: 620px; */
    margin-bottom: 23px;
  }

  .pyment-img>img {
    width: 100%;
  }

  .basic-plan {
    margin: 0px 17px 20px 17px;
  }

  .stand-plan {
    margin: 0px 17px 20px 17px;
  }

  .pre-plan {
    margin: 0px 17px 20px 17px;
  }

  .card {
    margin-bottom: 15px;
  }

  .Percentage-icon {
    position: absolute;
    right: 20px;
    bottom: 18px;
  }

  .font-40 {
    font-size: 33px;
  }

  .margin-top {
    margin-top: 20px;
  }

  ul.uloading-instruction {
    margin: 0px;
    line-height: 24px;
    margin-left: 0px;
  }

  // .form.form-width {
  //   width: 87%;
  // }

  img.img-logo-1 {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .mat-expansion-panel-header {
    height: 45px !important;
  }

  .mobile-icon-shipment {
    display: block;
  }

  .mobile-icon-shipment {
    padding: 20px 20px 0px;
  }

  .col-lg-4.cards-div {
    width: 100%;
  }

  .col-lg-8.px-3.mt-2.adde {
    margin-right: 0px !important;
    padding-right: 8px !important;
  }

  .col-lg-4.pl-3.mt-2.padding-r.addi-sender1 {
    padding-right: 15px;
  }

  .col-lg-3.pr-3.py-3.arrival-shipment {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  .help-search {
    margin: 0px 20px;
  }

  .searching-icon1 {
    position: absolute;
    right: 26px;
    top: 18px;
    height: 14px;
  }

  .inner-dashbaord {
    padding: 28px 0px 0px 9px !important;
  }

  .side-container section ul li.active {
    color: #fff;
    background: #586268;
    border-left: 4px solid #f8a23a !important;
    border-top: 0;
    border-bottom: 0;
  }

  // .form-login {
  //   position: absolute;
  //   top: 112px;
  //   bottom: 10px;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   z-index: 999;
  //   border-radius: 20px;
  //   max-width: 90%;
  //   width: 100%;
  //   height: 522px;
  //   background-color: #fff;
  // }

  // .form.form-width {
  //   width: 91%;
  // }

  ul.uloading-instruction {
    margin: 0px;
    line-height: 24px;
    margin-left: 0px;
  }

  .padding-both-side {
    padding: 10px;
  }

  .margin-top {
    margin-top: 20px;
  }

  .font-40 {
    font-size: 33px;
  }

  .getStarted1 {
    color: #d0333a;
    margin: 0px 0px;
    font-weight: 600;
  }

  .col-lg-3.pr-2.search.padding-l {
    padding-left: 10px;
  }

  .Percentage-icon {
    position: absolute;
    right: 20px;
    bottom: 18px;
  }

  .card {
    margin-bottom: 15px;
  }

  .ng-otp-input-wrapper .otp-input:not(:last-child) {
    margin-right: 3px !important;
  }

  .pl-2 {
    padding-left: 0 !important;
  }

  .pay-success>img {
    width: 100%;
  }

  .slect-card-img>img {
    width: 100%;
  }

  .card-detail {
    border-radius: 11px 11px 0px 0px;
    height: 80px;
    padding: 12px 25px;
  }
}

@media only screen and (max-width: 360px) {
  .mat-expansion-panel-header {
    height: 45px !important;
  }

  .mat-expansion-panel-header {
    height: 49px !important;
  }
}

.noRequest {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

iframe.iframeClass {
  width: 98%;
  height: 280px;
}

.custom-height {
  width: 15px !important;
}

@media (max-width: 414px) {
  .panel-header {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .options {
    // bottom: -114px;
    bottom: 0px;
  }

  // .filter-date{
  //   margin-right: 0;
  //   margin-left:0 ;
  // }

  .mr-right {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .ship-mobile img {
    height: 22px;
    object-fit: contain;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .ship-mobile {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}

.filter-date input::placeholder {
  color: #363738 !important;
  font-weight: 500 !important;
}

@media (max-width: 545px) {
  .header-dashboard {
    order: 0;
  }

  .mobile-header {
    // flex-wrap: wrap;
    gap: 15px;
  }

  .order-set {
    order: 1;
  }

  .defaul {
    padding: 0px;
  }

  .edit-icon {
    width: 45px;
    height: 45px;
    margin-left: 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

table tr th {
  white-space: nowrap;
}

// mat autcomplete form fields
.f-group {
  /* margin-top: 1rem; */
  position: relative;

  .f-control {
    display: flex;
    width: 100%;
    padding: 5px 12px 2px 8px;
    border-radius: 10px;
    border: 1px solid #afb0c9;
    align-items: center;
    margin-bottom: 0px;
    min-height: 52px;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
}

input,
textarea {
  border: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  padding: 5px 10px 7px 5px;
  height: 30px;
  background-color: transparent;
  color: #222b45;
  font-size: 15px;
}

.f-group input::placeholder {
  color: #a0a0a0;
  font-size: 13px;
}

//ng select

.ng-select .ng-select-container {
  height: 52px !important;
  padding: 21px 10px;
  border-radius: 10px;
  border: 1px solid #afb0c9;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #afb0c9;
  box-shadow: none;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input::placeholder {
  color: #a0a0a0;
}

.ng-select.ng-select-opened>.ng-select-container {
  border-color: #afb0c9;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  color: #9a9aba;
}

.ng-select div,
.ng-select input,
.ng-select span {
  color: #383d56;
  // line-height: 28px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #9a9aba;
  font-size: 13px !important;
}

.ng-value-container {
  padding-left: 3px !important;
}

.hide-input .ng-input {
  display: none;
}

.ng-select span {
  box-sizing: border-box;
  font-size: 13px !important;
}

// @media (max-width:991px){

// }
.pl-30 {
  padding-left: 30px;
}

.filter-icon {
  margin-top: 6px !important;
}

.flex-button {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 5px;
  gap: 10px;
}

.mr-0 {
  margin-right: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

// confirm dialog style

.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.confirm-text {
  text-align: start;
  font-size: 16px;
}

.confirm-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.confirmdelete>.mat-dialog-container {
  border-radius: 20px !important;
}

//  confrim dialog style end

.p-20 {
  padding: 20px !important;
}

.universl-red-btn {
  background: transparent linear-gradient(270deg, #d0333a 0%, #dc5e63 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 8px 10px rgba(208, 51, 58, 0.2);
  font-size: 14px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 50px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  gap: 5px;
}

.yellow-btn {
  background: #f7a13a;
  color: #fff;
  font-size: 12px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 50px;
  height: 30px;
  cursor: pointer;
  padding: 0px 10px;
}

.invoice-checkbox {
  width: 12px;
  height: 12px;
  accent-color: black;
}

.flex-input-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bg-red {
  background-color: #d139401a;
}

.bg-wihte {
  background-color: #fff;
}

.button-flex-uiniversal {
  display: flex;
  gap: 10px;
}

.flex-center-button {
  display: flex;
  justify-content: center;
}

.gap-20 {
  gap: 20px!important;
}

.gap-10 {
  gap: 10px;
}

.mt-25 {
  margin-top: 2.5rem;
}

.theme-btn.red.home {
  background: #d0333a;
  color: black;
  font-weight: 500;
  font-size: 15px;
  width: 98px;
  padding: 10px 20px;
  box-shadow: none;

  &:hover {
    background: #c22f36;
  }
}

.theme-btn.gray.home {
  background: #ffffff14;
  color: white;
  font-weight: 500;
  font-size: 15px;
  width: 98px;
  padding: 10px 20px;
  box-shadow: none;
  transition: all 0.5s ease-in;

  &:hover {
    background: #ffffff2c;
  }
}

.gradient-bg {
  font-size: 72px;
  width: max-content;
  background-image: linear-gradient(90deg, white 2%, #d0333a);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 60px;
}

.spacer-16 {
  background-color: #0000;
  flex: none;
  width: 1rem;
  height: 1rem;
  display: block;
}

.spacer-128 {
  background-color: #0000;
  flex: none;
  width: 8rem;
  height: 8rem;
}

.spacer-32 {
  background-color: #0000;
  border-radius: 0.375rem;
  flex: none;
  width: 2rem;
  height: 2rem;
}

.spacer-24 {
  background-color: #0000;
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
}

.spacer-96 {
  background-color: #0000;
  flex: none;
  width: 6rem;
  height: 6rem;
}

.spacer-80 {
  background-color: #0000;
  flex: none;
  width: 5rem;
  height: 5rem;
}

.divider-line {
  background-color: #ffffff0f;
  width: 100%;
  height: 0.0625rem;
}

.spacer-40 {
  background-color: #0000;
  flex: none;
  width: 2.5rem;
  height: 2.5rem;
}

.text-mat-yellow {
  color: #ffda8f;
}

.heading-regular {
  letter-spacing: -0.02em;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Switzer, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: white;
}

.grid-3-coulmn {
  grid-column-gap: 5rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-items: start;
  display: grid;
}

.footer-column {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
}

.testimonial-image {
  object-fit: cover;
  flex: none;
  width: 100%;
  height: 100%;
}

.testimonial-image-wrapper {
  padding: 0.4rem;
  border-radius: 0.5rem;
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  background: white;
}

.testimonial-details {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  color: var(--400);
  align-items: center;
  display: flex;
}

.testimonial-details-text {
  grid-column-gap: 0.125rem;
  grid-row-gap: 0.125rem;
  color: var(--400);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.main-section-v2 {
  background-color: #000;
  overflow-y: scroll;
  height: 100dvh;
}

.h-dvh {
  height: 100dvh;
}

.pt-8rem {
  padding-top: 8rem !important;
}

.w-49 {
  width: 49rem;
}

.image-grid {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  place-items: stretch stretch;
  display: grid;
}

.line-h-32 {
  line-height: 32px;
}

.flex-login-signup-new {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  padding: 3.5rem 2.5rem;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-image: url("assets/home/background.png");
  transition: all 1s ease;
}

.subscribe>.mat-dialog-container {
  border-radius: 20px !important;
  background-color: black;
  box-shadow: 0px 0px 8px 0px #202221;
}

.gap-3 {
  gap: 3px;
}

.gap-5 {
  gap: 5px;
}